import React, { useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ArticoloMuseoUnk from '../images/LibroUnk.jpg'

const FormAggiungiMuseo = ({setErrorSub, setSuccessSub} : {setErrorSub : React.Dispatch<React.SetStateAction<boolean>>, setSuccessSub : React.Dispatch<React.SetStateAction<boolean>>}) => {
    const [immagine, setImmagine] = useState<File | null>(null);
    const [valueImmagine, setValueImmagine] = useState<string | undefined>(undefined);
    const [nomeArticoloMuseo, setNomeArticoloMuseo] = useState("");
    const [descrizione, setDescrizione] = useState("");

    const [errorImageMessage, setErrorImageMessage] = useState("");
    const [errorNomeArticoloMuseoMessage, setErrorNomeArticoloMuseoMessage] = useState("");
    const [errorImmagineSub, setErrorImmagineSub] = useState(false);
    const [errorNomeArticoloMuseoSub, setNomeArticoloMuseoSub] = useState(false);
    
    const refDragImage = useRef(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files) {
            setValueImmagine(undefined);
            setImmagine(e.target.files[0]);
        }
    };

    const aggiungiArticoloMuseo = async () => {
        setErrorImmagineSub(false);
        setNomeArticoloMuseoSub(false);
        setSuccessSub(false);
        setErrorSub(false);

        const auth = localStorage.getItem('Authorization') || sessionStorage.getItem('Authorization');

        if (!immagine) {
            setErrorImmagineSub(true);
            setErrorImageMessage("Inserire l'immagine dell'articolo...");
            return;
        }
        if (immagine.type !== "image/png" && immagine.type !== "image/jpeg") {
            setErrorImmagineSub(true);
            setErrorImageMessage("File invalido...");
            return;
        }
        setErrorImmagineSub(false);
        if (nomeArticoloMuseo === "") {
            setNomeArticoloMuseoSub(true);
            setErrorNomeArticoloMuseoMessage("Inserire il nome dell'articolo...");
            return;
        }
        setNomeArticoloMuseoSub(false);
        
        console.log("Aggiunta in corso...");
    
        const formData = new FormData();
        formData.append("file", immagine);
        formData.append("nome", nomeArticoloMuseo.trim());
        formData.append("descrizione", descrizione);

        try {
            // You can write the URL of your server or any other endpoint used for file upload
            const resultImmagine = await fetch("https://edizionirebis.com:57447/inserisci-museo", {
                method: "POST",
                headers: { 'Authorization': `Bearer ${auth}` },
                body: formData
            });

            if (resultImmagine.status === 200) {
                const result = await resultImmagine.json();
                console.log(result)
                setErrorSub(false);
                setSuccessSub(true);
            } else {
                setSuccessSub(false);
                setErrorSub(true);
            }

        } catch (error) {
            console.log(error);
            setSuccessSub(false)
            setErrorSub(true);
        }

        setValueImmagine(undefined)
        setNomeArticoloMuseo("");
        setImmagine(null);

        setDescrizione("");
    }

    function onEnterImage(e: React.DragEvent<HTMLLabelElement>) {
        e.preventDefault();
        (refDragImage.current as any).className = 'file-input active';
    }

    function onLeaveImage(e: React.DragEvent<HTMLLabelElement>) {
        e.preventDefault();
        (refDragImage.current as any).className = 'file-input'
    }

    return (
        <Form className="mt-3" onSubmit={(e) => {
            e.preventDefault();
            aggiungiArticoloMuseo();
            }
        }
        id="addArticoloMuseo">
            <br/><br/>
                    <div className='text-center'>
                        <p className='testoBlu'>ANTEPRIMA:</p>
                        {
                            immagine ?
                            <img id={"ArticoloMuseo"+immagine.name} src={URL.createObjectURL(immagine)} alt={immagine.name} width="165px" height="200px" className='notiziaCopertina libro'/>
                            :
                            <img id={"ArticoloMuseoUnknown"} src={ArticoloMuseoUnk} alt={"ArticoloMuseoUnknown"} width="165px" height="200px" className='antiquariatoCopertina libro'/>
                        }<br/> <br/>
                        <label className="file-input d-inline-grid w-100" htmlFor="file"
                               onDragEnter={onEnterImage} 
                               onDragEnd={onLeaveImage} 
                               onDragLeave={onLeaveImage} 
                               onDragExit={onLeaveImage} 
                               onMouseLeave={onLeaveImage}
                               ref={refDragImage}>
                            <div className="drop-zone">
                                <p><b>Inserisci o trascina</b> l'immagine dell'articolo</p>
                            </div>
                            <input type="file" id="file" 
                            onChange={handleFileChange}
                            accept="image/*"
                            value={valueImmagine}
                            placeholder="Immagine articolo"
                            />
                        </label>
                    </div>
                    <br/><br/>
                    <h5 className='text-danger' style={{display: (errorImmagineSub ? 'initial' : 'none'), marginBottom:10}}>
                        {errorImageMessage}
                    </h5>

            <Form.Group className="mb-3" controlId="formBasicNome">
                <Form.Label>Titolo articolo</Form.Label>
                <Form.Control isInvalid={errorNomeArticoloMuseoSub} type="text" placeholder="Titolo articolo" value={nomeArticoloMuseo} 
                onChange={(e) => {
                    setNomeArticoloMuseo(e.target.value)} 
                }
                />
                <Form.Control.Feedback type="invalid">
                    {errorNomeArticoloMuseoMessage}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicDescrizione">
                <Form.Label>Descrizione</Form.Label>
                <Form.Control type="text" rows={descrizione.length > 50 ? 10 : 2} as="textarea" maxLength={15000} placeholder="Descrizione"
                value={descrizione} 
                onChange={(e) => {
                    setDescrizione(e.target.value);
                    }
                } />
            </Form.Group>

            <Button variant="outline-primary" className="btnFormSend w-100" type="submit">
                Aggiungi
            </Button>
        </Form>
    )
}

export default FormAggiungiMuseo;