import React from 'react';
import './Home.css';
import { useNavigate } from 'react-router-dom';


//FOOTER
const Footer = () => {

	const navigate = useNavigate();

	const routeNav = (path : string) =>{
		navigate(path);
	}

  return (
	<div id="FooterContent">
		<div className="container-fluid text-center" style={{zIndex:10}}>
			<div className="row align-items-end footerSizeText">

				<div className="col-lg-4 col-sm-12" style={{alignSelf:'stretch'}}>
					<div id="Centro_Editoriale_Rebis_bm" className='p-2'>
						<span>Centro Editoriale Rebis</span>
					</div>
					<div id="Indirizzo_Via_Venezia_42">
						<span>Indirizzo: Via Venezia, 42</span>
					</div>
					<div id="Viareggio_Lu">
						<span>Viareggio (Lu)</span>
					</div>
					<div id="infonuovositocom" className='p-2'>
						<span>info@edizionirebis.com</span>
					</div>
				</div>

				<div className="col-lg-4 col-sm-12 borderFooter">
					<div id="Per_i_tuoi_acquisti" className='p-3 p-lg-5'>
						<span>Per i tuoi acquisti</span>
						<div id="Clicca_qui_bw" 
						className='mt-2 mt-lg-4'
						style={{border:'1px solid rgba(255,255,255,1)', borderRadius: 50, paddingRight:5, paddingLeft:5, cursor:'pointer'}}
						onClick={() => window.open("https://www.edizionirebis.it/", "_blank")}
						>
							<span>Clicca qui</span>
						</div>
					</div>

				</div>
				

				<div className="col-lg-4 col-sm-12" style={{alignSelf:'stretch'}}>
					<div id="Chi_Siamo" className='p-2' onClick={() => routeNav("chi-siamo")}>
						<span>Chi Siamo</span>
					</div>
					<div id="Privacy_Policy" className='p-3' onClick={() => routeNav("privacy-policy")}>
						<span>Privacy Policy</span>
					</div>
					<div id="Cookie_Policy" className='p-2' onClick={() => routeNav("cookie-policy")}>
						<span>Cookie Policy</span>
					</div>
				</div>

			</div>
		</div>

	</div>
);
}


export default Footer;