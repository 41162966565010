import React, { useRef, useState } from "react";
import { libro } from "./FetchingData";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import Form from "react-bootstrap/esm/Form";

const PannelloChangeLibro = ({libro, setErrorSub, setSuccessSub} : {libro: libro, setErrorSub : React.Dispatch<React.SetStateAction<boolean>>, setSuccessSub : React.Dispatch<React.SetStateAction<boolean>>}) => {

    const [showModifica, setShowModifica] = useState(false);

    const handleModificaClose = () => setShowModifica(false);
    const handleModificaShow = () => setShowModifica(true);

    const [showCancella, setShowCancella] = useState(false);

    const handleCancellaClose = () => setShowCancella(false);
    const handleCancellaShow = () => setShowCancella(true);


    const [errorImmagineSub, setErrorImmagineSub] = useState(false);
    const [errorNomeLibroSub, setNomeLibroSub] = useState(false);
    const [errorCatalogoSelezionatoSub, setCatalogoSelezionatoSub] = useState(false);

    const [errorImageMessage, setErrorImageMessage] = useState("");
    const [errorNomeLibroMessage, setErrorNomeLibroMessage] = useState("");
    const [errorCatalogoSelezionatoMessage, setErrorCatalogoSelezionatoMessage] = useState("");

    const [immagine, setImmagine] = useState<File | null>(null);
    const [valueImmagine, setValueImmagine] = useState<string | undefined>(undefined);
    const [nomeLibro, setNomeLibro] = useState(libro.nome);
    const [catalogoSelezionato, setCatalogoSelezionato] = useState(libro.catalogo);
    const [prezzo, setPrezzo] = useState(libro.prezzo ?? "0,00");

    const [linkSito, setLinkSito] = useState(libro.linkSito ?? "");
    const [descrizione, setDescrizione] = useState(libro.descrizione ?? "");
    const [isNovita, setIsNovita] = useState(libro.isnovita ? true : false);

    const refDragImage = useRef(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.files) {
          setValueImmagine(undefined);
          setImmagine(e.target.files[0]);
        }
      };

    // MODIFICA LIBRO CON IMMAGINE

    const modificaLibroImmagine = async () => {
        setErrorSub(false);
        setSuccessSub(false);

        setErrorImmagineSub(false);
        setNomeLibroSub(false);
        setCatalogoSelezionatoSub(false);

        const auth = localStorage.getItem('Authorization') || sessionStorage.getItem('Authorization');

        if (!immagine) {
            setErrorImmagineSub(true);
            setErrorImageMessage("Inserire l'immagine del libro...");
            return;
        }
        if (immagine && immagine.type !== "image/png" && immagine.type !== "image/jpeg") {
            setErrorImmagineSub(true);
            setErrorImageMessage("File invalido...");
            return;
        }
        setErrorImmagineSub(false);
        if (nomeLibro === "") {
            setNomeLibroSub(true);
            setErrorNomeLibroMessage("Inserire il nome del libro...");
            return;
        }
        setNomeLibroSub(false);
        if (!catalogoSelezionato || catalogoSelezionato === "0") {
            setCatalogoSelezionatoSub(true);
            setErrorCatalogoSelezionatoMessage("Selezionare il catalogo del libro...");
            return;
        }
        setCatalogoSelezionatoSub(false);
        if (prezzo.length === 0) setPrezzo("0.00");

        const formData = new FormData();
        formData.append("file", immagine);
        formData.append("nome", nomeLibro.trim());
        formData.append("catalogo", catalogoSelezionato);
        formData.append("nomeVecchio", libro.nome);
        formData.append("catalogoVecchio", libro.catalogo);
        formData.append("linkSito", linkSito.trim());
        formData.append("descrizione", descrizione);
        formData.append("prezzo", prezzo.trim());
        formData.append("urlVecchio", libro.url);
        formData.append("isnovita", isNovita.toString());

        console.log("Modifica in corso...");

        try {
            // You can write the URL of your server or any other endpoint used for file upload
            const resultModifica = await fetch("https://edizionirebis.com:57447/modifica-libro-immagine", {
                method: "POST",
                headers: { 'Authorization': `Bearer ${auth}`},
                body: formData
            });

            if (resultModifica.status === 200) {
                const data = await resultModifica.json();
                console.log(data);
                setErrorSub(false);
                setSuccessSub(true);
            } else {
                setErrorSub(true);
                setSuccessSub(false);
            }

        } catch (error) {
            console.log(error);
            setErrorSub(true);
            setSuccessSub(false);
            return;
        }

        setValueImmagine(undefined)
        setNomeLibro(libro.nome);
        setCatalogoSelezionato(libro.catalogo);
        setPrezzo(libro.prezzo ?? "0.00");
        setLinkSito(libro.linkSito ?? "");
        setDescrizione(libro.descrizione ?? "");
        setIsNovita(libro.isnovita ? true : false);
        handleModificaClose();
        window.location.reload();
    }

    // MODIFICA SENZA IMMAGINE LIBRO

    const modificaLibro = async () => {
        setErrorSub(false);
        setSuccessSub(false);

        setNomeLibroSub(false);
        setCatalogoSelezionatoSub(false);

        const auth = localStorage.getItem('Authorization') || sessionStorage.getItem('Authorization');

        if (nomeLibro === "") {
            setNomeLibroSub(true);
            setErrorNomeLibroMessage("Inserire il nome del libro...");
            return;
        }
        setNomeLibroSub(false);
        if (!catalogoSelezionato || catalogoSelezionato === "0") {
            setCatalogoSelezionatoSub(true);
            setErrorCatalogoSelezionatoMessage("Selezionare il catalogo del libro...");
            return;
        }
        setCatalogoSelezionatoSub(false);
        if (prezzo.length === 0) setPrezzo("0.00");

        const send = { nome: nomeLibro.trim(), catalogo: catalogoSelezionato, 
                       nomeVecchio: libro.nome, catalogoVecchio: libro.catalogo, prezzo: prezzo.trim(),
                       linkSito: linkSito.trim(), descrizione: descrizione, isnovita: isNovita.toString()
                    }

        console.log("Modifica in corso...");

        try {
            // You can write the URL of your server or any other endpoint used for file upload
            const resultModifica = await fetch("https://edizionirebis.com:57447/modifica-libro", {
                method: "POST",
                headers: { 'Authorization': `Bearer ${auth}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(send)
            });

            if (resultModifica.status === 200) {
                const data = await resultModifica.json();
                console.log(data);
                setErrorSub(false);
                setSuccessSub(true);
            } else {
                setErrorSub(true);
                setSuccessSub(false);
            }

        } catch (error) {
            console.log(error);
            setErrorSub(true);
            setSuccessSub(false);
            return;
        }

        setNomeLibro(libro.nome);
        setCatalogoSelezionato(libro.catalogo);
        setPrezzo(libro.prezzo ?? "0.00");
        setIsNovita(libro.isnovita ? true : false);
        handleModificaClose();
        window.location.reload();
    }

    // CANCELLA LIBRO

    const cancellaLibro = async () => {
        setErrorSub(false);
        setSuccessSub(false);
        
        const auth = localStorage.getItem('Authorization') || sessionStorage.getItem('Authorization');

        try {
            const postObject = {nome: libro.nome, catalogo: libro.catalogo, url: libro.url};

            // You can write the URL of your server or any other endpoint used for file upload
            const resultElimina = await fetch("https://edizionirebis.com:57447/elimina-libro", {
                method: "POST",
                headers: { 'Authorization': `Bearer ${auth}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(postObject)
            });

            if (resultElimina.status === 200) {
                const data = await resultElimina.json();
                console.log(data);
                setErrorSub(false);
                setSuccessSub(true);
            } else {
                setErrorSub(true);
                setSuccessSub(false);
            }

        } catch (error) {
            console.log(error);
            setErrorSub(true);
            return;
        }
        
        handleCancellaClose();
    }

    function onEnterImage() {
        (refDragImage.current as any).className = 'file-input active';
    }

    function onLeaveImage() {
        (refDragImage.current as any).className = 'file-input'
    }

    return (
    <>
    <div className='align-self-center'>
        <img id={"Libro"+libro.nome+libro.catalogo} src={libro.url} alt={libro.nome} width="165px" height="200px" className='m-auto d-block libroCopertina libro'/>
        <div className="mt-2" style={{margin:'auto', maxWidth:'75%'}}>
            <div id={libro.nome} style={{borderBottom:'2px solid rgba(221,0,0,1)'}} className="nomeLibro">
                <span>{libro.nome}</span>
            </div>
            <div id={ libro.catalogo } className="catalogoLibro">
                <span>{ libro.catalogo }</span>
            </div>
        </div>
        <div className="text-center mt-3">
            <Button onClick={handleModificaShow} variant="outline-secondary" aria-label="Modifica">Modifica</Button>{' '}
            <Button onClick={handleCancellaShow} variant="outline-danger" aria-label="Cancella">Elimina</Button>
        </div>
    </div>
    <Modal show={showModifica} onHide={handleModificaClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Modifica {libro.nome}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <Form onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        immagine ? modificaLibroImmagine() : modificaLibro()
                        }
                    }>
                    <br />
                    <div className='text-center'>
                        <p className='testoRosso'>ANTEPRIMA:</p>
                        {
                            immagine ?
                            <img id={"Libro"+immagine.name} src={URL.createObjectURL(immagine)} alt={immagine.name} width="165px" height="200px" className='libroCopertina libro'/>
                            :
                            <img id={"Libro"+libro.nome+libro.catalogo} src={libro.url} alt={"Libro"+libro.nome+libro.catalogo} width="165px" height="200px" className='libroCopertina libro'/>
                        }<br /> <br />
                        <label className="file-input d-inline-grid w-100" htmlFor="file"
                               onDragEnter={onEnterImage} 
                               onDragEnd={onLeaveImage} 
                               onDragLeave={onLeaveImage} 
                               onDragExit={onLeaveImage}
                               onMouseLeave={onLeaveImage}
                               ref={refDragImage}>
                            <div className="drop-zone">
                                <p><b>Inserisci o trascina</b> l'immagine del libro</p>
                            </div>
                            <input type="file" id="file" 
                            onChange={handleFileChange}
                            accept="image/jpeg"
                            value={valueImmagine}
                            placeholder="Immagine libro"
                            />
                        </label>
                    </div>
                    <br /><br />
                    <h5 className='text-danger' style={{display: (errorImmagineSub ? 'initial' : 'none'), marginBottom:10}}>
                        {errorImageMessage}
                    </h5>   


                        <Form.Group className="mb-3" controlId={"formBasicModificaNome"+libro.nome+libro.catalogo}>
                            <Form.Label>Nome del libro</Form.Label>
                            <Form.Control isInvalid={errorNomeLibroSub} type="text" placeholder="Nome del libro" value={nomeLibro} 
                            onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setNomeLibro(e.target.value)} 
                            }
                            />
                            <Form.Control.Feedback type="invalid">
                                {errorNomeLibroMessage}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId={"formBasicModificaCatalogo"+libro.nome+libro.catalogo}>
                            <Form.Label>Catalogo</Form.Label>
                                <Form.Select isInvalid={errorCatalogoSelezionatoSub} aria-label="Seleziona il catalogo" 
                                onChange={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setCatalogoSelezionato(e.target.value)
                                }} value={catalogoSelezionato}
                                >
                                    <option value="Abate Julio">Abate Julio</option>
                                    <option value="Esoterismo">Esoterismo</option>
                                    <option value="Alchimia">Alchimia</option>
                                    <option value="Grimoires">Grimoires</option>
                                    <option value="Angeli">Angeli</option>
                                    <option value="Magia Pratica">Magia Pratica</option>
                                    <option value="Arti Divinatorie">Arti Divinatorie</option>
                                    <option value="Magia Rituale">Magia Rituale</option>
                                    <option value="Cabala">Cabala</option>
                                    <option value="Opere Pierini">Opere Pier Luca Pierini</option>
                                    <option value="Colonna I Gioielli">Colonna I Gioielli</option>
                                    <option value="Rivista Elixir">Rivista Elixir</option>
                                    <option value="Ermetismo">Ermetismo</option>
                                    <option value="Rosa † Croce">Rosa † Croce</option>
                                </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {errorCatalogoSelezionatoMessage}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId={"formBasicModificaPrezzo"+libro.nome+libro.catalogo}>
                            <Form.Label>Prezzo</Form.Label>
                            <Form.Control type="number" step={.01} placeholder="0.00" min="0" value={prezzo} 
                            onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                const str = e.target.value
                                if (str.charAt(str.length - 1) === '.') { return }
                                setPrezzo(str);
                                }
                            } />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId={"formBasicLinkSito"+libro.nome+libro.catalogo}>
                            <Form.Label>Link</Form.Label>
                            <Form.Control type="url" placeholder="https://"
                            value={linkSito} 
                            onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setLinkSito(e.target.value)
                                } 
                            }/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId={"formBasicDescrizione"+libro.nome+libro.catalogo}>
                            <Form.Label>Descrizione</Form.Label>
                            <Form.Control type="text" as="textarea" rows={descrizione.length > 50 ? 10 : 2} maxLength={15000} placeholder="Descrizione"
                            value={descrizione} 
                            onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setDescrizione(e.target.value);
                                }
                            } />
                        </Form.Group>
                        
                        <Form.Group className="mb-3" controlId={"formBasicIsNovita"+libro.nome+libro.catalogo}>
                            <Form.Check type="checkbox" label="E' una novità?" defaultChecked={isNovita} onChange={() => setIsNovita(!isNovita)}/>
                        </Form.Group>

                        <Form.Group className="text-end float-end w-50">
                            <Button variant="outline-success" type="submit">
                                Modifica
                            </Button>
                            {' '}
                            <Button variant="outline-secondary" onClick={handleModificaClose}>
                                Chiudi
                            </Button>
                        </Form.Group>
                    </Form>

        </Modal.Body>
      </Modal>

      <Modal show={showCancella} onHide={handleCancellaClose}>
        <Modal.Header closeButton>
          <Modal.Title className="">Sei sicuro di voler cancellare {libro.nome}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="float-end text-end">
                <Button variant="outline-danger" onClick={cancellaLibro}>
                    Cancella
                </Button>
                {' '}
                <Button variant="outline-secondary" onClick={handleCancellaClose}>
                    Chiudi
                </Button>
            </div>
        </Modal.Body>
      </Modal>
    </>
    )
}

export default PannelloChangeLibro;