import React from "react";
import ArticoloMuseo from "./componenti/ArticoloMuseo";
import fotoMuseo from './images/fotoMuseo.png'
import { articoloMuseo } from "./componenti/FetchingData";

const Museo = ({articoliMuseo} : {articoliMuseo : articoloMuseo[]}) => {

    return (
        <>
        <img src={fotoMuseo} alt="header" className="w-100"/>
        <div id="Edizioni_Rebis_Home" className="container-fluid pt-5 pb-5" style={{backgroundColor:'rgba(232,232,232,1)', fontWeight:'bold', fontFamily:'Abril Display'}}>
        <div className="text-center fs-1 fw-bolder text-decoration-underline mt-3" style={{ color:'rgba(221,0,0,1)'}}>Museo</div>
            {
                articoliMuseo.map((elem, index) => {
                    return (
                        <div key={elem.titolo+index} className="row justify-content-center" style={{borderBottom:'4px solid rgba(1,65,189,1)'}}>
                            <ArticoloMuseo articoloMuseo={elem} numero={index} />
                        </div>
                    )
                })
            }
        </div>
        </>
    );
}

export default Museo;