import React from "react";
import Sfondo_libri from './images/n_draghi.png'

const PrivacyPolicy = () => {
    return (
        <div id="Edizioni_Rebis_Home" style={{backgroundImage:'url("'+ Sfondo_libri +'")', backgroundColor:'rgba(255,255,255,1)', backgroundPosition:'center center', backgroundSize:'contain', backgroundRepeat:'no-repeat'}} className='container-fluid p-5'>
		    <div className="row justify-content-center" style={{backgroundColor:'rgba(232,232,232,0.3)', borderRadius:'25px', backdropFilter:'blur(2px)'}}>
                <div className="col-lg-6 align-self-center p-5 text-center" style={{fontFamily:'Abril Display', fontStyle:'normal'}}>
		                <span style={{fontSize:35, color:'rgba(1,65,189,1)'}}>PRIVACY POLICY</span><br /><br />
                        <p className="textRobeInformazioni">
                                <b><p style={{fontSize:'21px', display:'inline'}}>1. </p>Titolare del Trattamento</b><br />
                                Edizioni Rebis, con sede in Via Venezia, 42, 55049 Viareggio LU, CF e P. Iva 00416030468 (in seguito, "Titolare" o "Edizioni Rebis"), in qualità di Titolare del Trattamento, informa ai sensi degli articoli 13 e 14 del Regolamento UE n. 2016/679 (in seguito, "GDPR") che i Dati di persone fisiche residenti nell'Unione Europea (in seguito "Cliente" o "Interessato"), di cui l'azienda è venuta in possesso durante lo svolgimento della propria attività, saranno trattati con le modalità e per le finalità seguenti.<br />
                                <br />
                                <b><p style={{fontSize:'21px', display:'inline'}}>2. </p>Oggetto del Trattamento</b><br />
                                Il Titolare tratta i dati personali (ad esempio nome, cognome, codice fiscale, email, numero telefonico, ecc. in seguito, "dati personali" o anche "dati") acquisiti anche verbalmente o comunicati dall'Interessato in fase di registrazione al sito web e/o all'atto dell'iscrizione al servizio di newsletter del Titolare o all'atto dell'esecuzione di un contratto in cui l'Interessato è parte.<br />
                                <br />
                                <b><p style={{fontSize:'21px', display:'inline'}}>3. </p>Base giuridica e Finalità del Trattamento</b><br />
                                I dati personali sono trattati:<br />
                                <b>A.</b> senza il consenso espresso dell'Interessato (art. 6 lett. B del GDPR), per realizzare l'oggetto sociale del Titolare, in particolare:<br />
                                a) adempiere agli obblighi precontrattuali, contrattuali e fiscali derivanti da rapporti in essere con il Cliente;<br />
                                b) adempiere agli obblighi previsti dalla legge, da un regolamento, dalla normativa comunitaria o da un ordine dell'Autorità;<br />
                                c) permettere l'iscrizione al servizio di newsletter fornito dal Titolare e degli ulteriori Servizi eventualmente richiesti;<br />
                                d) esercitare i diritti del Titolare.<br />
                                <b>B.</b> previo specifico consenso dell'Interessato (art. 6 lett. A del GDPR), mediante Informativa specifica e separata da questa, per le finalità in essa indicate.<br />
                                Si segnala che ai clienti della Edizioni Rebis, potranno essere inviate comunicazioni commerciali relative a servizi e prodotti del Titolare analoghi a quelli di cui il Cliente ha già usufruito, salvo dissenso espresso dell'Interessato.<br />
                                <br />
                                <b><p style={{fontSize:'21px', display:'inline'}}>4. </p>Modalità del Trattamento</b><br />
                                Il trattamento dei dati personali del Cliente è realizzato per mezzo delle operazioni indicate all'art. 4 n. 2 del GDPR e precisamente: raccolta, registrazione, organizzazione, conservazione, consultazione, elaborazione, modifica, selezione, estrazione, raffronto, utilizzo, interconnessione, blocco, comunicazione, cancellazione e distruzione dei dati. I dati personali sono sottoposti a trattamento sia cartaceo che elettronico.<br />
                                <br />
                                Il Titolare tratterà i dati personali per il tempo necessario per adempiere alle finalità di cui all'art. 3 della presente informativa e comunque per non oltre 10 anni dalla cessazione del rapporto con il Cliente. I dati raccolti e non soggetti a conservazione determinata dalla legge saranno conservati per non oltre 2 anni dalla cessazione del rapporto con l'Interessato.<br />
                                <br />
                                I dati saranno inoltre trattati nel rispetto del principio di riservatezza e di sicurezza, in particolare, saranno adottate tutte le misure tecniche, informatiche, organizzative e procedurali di sicurezza in modo che sia garantito il livello adeguato di protezione dei dati indicati dall'art. 32 del GDPR.<br />
                                <br />
                                <b><p style={{fontSize:'21px', display:'inline'}}>5. </p>Accesso ai Dati</b><br />
                                I dati del Cliente potranno essere resi accessibili per le finalità di cui all'art. 3.A) e 3.B) della presente informativa:<br />
                                a) a dipendenti e collaboratori del Titolare, nella loro qualità di incaricati e/o responsabili interni del trattamento e/o amministratori di sistema;<br />
                                b) a terzi soggetti (ad esempio, provider per la gestione e manutenzione del sito web, fornitori, istituti di credito, studi professionali, etc.) che svolgono attività in outsourcing per conto del Titolare, nella loro qualità di responsabili esterni del trattamento.<br />
                                <br />
                                <b><p style={{fontSize:'21px', display:'inline'}}>6. </p>Comunicazione dei Dati</b><br />
                                Senza espresso consenso del Cliente (art. 6 lett. b) e c) del GDPR), il Titolare potrà comunicare i dati per le finalità di cui all'art. 3.A) della presente informativa a Organismi di vigilanza, Autorità giudiziarie nonché a tutti gli altri soggetti ai quali la comunicazione sia obbligatoria per legge o necessaria per l'espletamento delle finalità dette.<br />
                                <br />
                                <b><p style={{fontSize:'21px', display:'inline'}}>7. </p>Trasferimento dei Dati</b><br />
                                La gestione e la conservazione dei dati personali avverranno su server del Titolare ubicati all'interno dell'Unione Europea e/o di società terze incaricate e debitamente nominate quali Responsabili del trattamento. Attualmente i server sono situati in Italia. I dati non saranno oggetto di trasferimento al di fuori dell'Unione Europea. Resta in ogni caso inteso che il Titolare, ove si rendesse necessario, avrà facoltà di spostare l'ubicazione dei server in Italia e/o Unione Europea e/o Paesi extra-UE. In tal caso, il Titolare assicura sin d'ora che il trasferimento dei dati extra-UE avverrà in conformità alle disposizioni di legge applicabili stipulando, se necessario, accordi che garantiscano un livello di protezione adeguato e/o adottando le clausole contrattuali standard previste dalla Commissione Europea.<br />
                                <br />
                                <b><p style={{fontSize:'21px', display:'inline'}}>8. </p>Natura del conferimento dei Dati e conseguenze del rifiuto di rispondere</b><br />
                                Il conferimento dei dati per le finalità di cui all'art. 3.A) della presente informativa è obbligatorio. Il mancato conferimento comporterà l'impossibilità per Edizioni Rebis di dare seguito al rapporto in essere con l'Interessato.<br />
                                <br />
                                <b><p style={{fontSize:'21px', display:'inline'}}>9. </p>Diritti dell'interessato</b><br />
                                Nella qualità di interessato, il Cliente ha i diritti di cui agli artt. 15 - 21 del GDPR e precisamente i diritti di:<br />
                                <br />
                                <b>I.</b> ottenere la conferma dell'esistenza o meno di dati personali che lo riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile;<br />
                                <b>II.</b> ottenere l'indicazione:<br />
                                a) dell'origine dei dati personali;<br />
                                b) delle finalità e modalità del trattamento;<br />
                                c) della logica applicata in caso di trattamento effettuato con l'ausilio di strumenti elettronici;<br />
                                d) degli estremi identificativi del titolare, dei responsabili e del rappresentante designato;<br />
                                e) dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono venirne a conoscenza in qualità di rappresentante designato nel territorio dello Stato, di responsabili o incaricati;<br />
                                <b>III.</b> ottenere:<br />
                                a) l'aggiornamento, la rettificazione ovvero, quando vi ha interesse, l'integrazione dei dati;<br />
                                b) la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o successivamente trattati;<br />
                                c) l'attestazione che le operazioni di cui alle lettere a) e b) sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un impiego di mezzi manifestamente sproporzionato rispetto al diritto tutelato;<br />
                                <b>IV.</b> opporsi, in tutto o in parte:<br />
                                a) per motivi legittimi al trattamento dei dati personali che lo riguardano, ancorché pertinenti allo scopo della raccolta;<br />
                                b) al trattamento di dati personali che lo riguardano a fini di invio di materiale pubblicitario o di vendita diretta o per il compimento di ricerche di mercato o di comunicazione commerciale, mediante l'uso di sistemi automatizzati di chiamata senza l'intervento di un operatore mediante email e/o mediante modalità di marketing tradizionali mediante telefono e/o posta cartacea. Si fa presente che il diritto di opposizione dell'interessato, esposto al precedente punto b), per finalità di marketing diretto mediante modalità automatizzate si estende a quelle tradizionali e che comunque resta salva la possibilità per l'interessato di esercitare il diritto di opposizione anche solo in parte. Pertanto, l'interessato può decidere di ricevere solo comunicazioni mediante modalità tradizionali ovvero solo comunicazioni automatizzate oppure nessuna delle due tipologie di comunicazione.<br />
                                Ove applicabili, il Cliente ha quindi i diritti di cui agli artt. 15-21 del GDPR, ovvero il diritto di accesso, il diritto di rettifica, il diritto all'oblio, il diritto di limitazione di trattamento, il diritto alla portabilità dei dati, il diritto di opposizione, nonché il diritto di reclamo a un'Autorità di Controllo.<br />
                                <br />
                                <b><p style={{fontSize:'21px', display:'inline'}}>10. </p>Modalità di esercizio dei diritti</b>
                                Il Cliente potrà in qualsiasi momento esercitare i propri diritti inviando:<br />
                                - una raccomandata A/R a Edizioni Rebis, Via Venezia, 42, 55049 Viareggio LU;<br />
                                - una e-mail all'indirizzo info@edizionirebis.com<br />
                                - una PEC all'indirizzo edizionirebis@pec.it<br /><br />
                                <b><p style={{fontSize:'21px', display:'inline'}}>11. </p>Minori</b><br />
                                I Servizi del Titolare non sono destinati a minori di 18 anni e il Titolare non raccoglie intenzionalmente informazioni personali riferite ai minori. Nel caso in cui informazioni su minori fossero involontariamente registrate, il Titolare le cancellerà in modo tempestivo, su richiesta dell'interessato.<br />
                                <br />
                                <b><p style={{fontSize:'21px', display:'inline'}}>12. </p>Titolare, responsabile e incaricati</b><br />
                                Il Titolare del trattamento è Edizioni Rebis in nome del proprio rappresentante legale.<br />
                                L'elenco aggiornato dei responsabili e degli incaricati al trattamento è custodito presso la sede del Titolare del trattamento.<br />
                                <br />
                                <b><p style={{fontSize:'21px', display:'inline'}}>13. </p>Modifiche alla presente Informativa</b><br />
                                La presente Informativa può subire variazioni. Si consiglia, quindi, di controllare regolarmente questa Informativa anche sul nostro sito internet e di riferirsi alla versione più aggiornata.
                                </p>
                </div>
			</div>
        </div>
    );
}

export default PrivacyPolicy;