import React, { useRef, useState } from "react";
import { antiquariato } from "./FetchingData";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import Form from "react-bootstrap/esm/Form";

const PannelloChangeAntiquariato = ({antiquariato, setErrorSub, setSuccessSub} : {antiquariato: antiquariato, setErrorSub : React.Dispatch<React.SetStateAction<boolean>>, setSuccessSub : React.Dispatch<React.SetStateAction<boolean>>}) => {

    const [showModifica, setShowModifica] = useState(false);

    const handleModificaClose = () => setShowModifica(false);
    const handleModificaShow = () => setShowModifica(true);

    const [showCancella, setShowCancella] = useState(false);

    const handleCancellaClose = () => setShowCancella(false);
    const handleCancellaShow = () => setShowCancella(true);

    const [errorImmagineSub, setErrorImmagineSub] = useState(false);
    const [errorNomeAntiquariatoSub, setNomeAntiquariatoSub] = useState(false);

    const [errorImageMessage, setErrorImageMessage] = useState("");
    const [errorNomeAntiquariatoMessage, setErrorNomeAntiquariatoMessage] = useState("");

    const [immagine, setImmagine] = useState<File | null>(null);
    const [valueImmagine, setValueImmagine] = useState<string | undefined>(undefined);
    const [nomeAntiquariato, setNomeAntiquariato] = useState(antiquariato.nome);
    const [prezzo, setPrezzo] = useState(antiquariato.prezzo ?? "0,00");

    const [linkSito, setLinkSito] = useState(antiquariato.linkSito ?? "");
    const [descrizione, setDescrizione] = useState(antiquariato.descrizione ?? "");

    const refDragImage = useRef(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.files) {
          setValueImmagine(undefined);
          setImmagine(e.target.files[0]);
        }
      };

    // MODIFICA ANTIQUARIATO CON IMMAGINE

    const modificaAntiquariatoImmagine = async () => {
        setErrorSub(false);
        setSuccessSub(false);

        setErrorImmagineSub(false);
        setNomeAntiquariatoSub(false);

        const auth = localStorage.getItem('Authorization') || sessionStorage.getItem('Authorization');

        if (!immagine) {
            setErrorImmagineSub(true);
            setErrorImageMessage("Inserire l'immagine dell'antiquariato...");
            return;
        }
        if (immagine && immagine.type !== "image/png" && immagine.type !== "image/jpeg") {
            setErrorImmagineSub(true);
            setErrorImageMessage("File invalido...");
            return;
        }
        setErrorImmagineSub(false);
        if (nomeAntiquariato === "") {
            setNomeAntiquariatoSub(true);
            setErrorNomeAntiquariatoMessage("Inserire il nome dell'antiquariato...");
            return;
        }
        setNomeAntiquariatoSub(false);
        if (prezzo.length === 0) setPrezzo("0.00");

        const formData = new FormData();
        formData.append("file", immagine);
        formData.append("nome", nomeAntiquariato.trim());
        formData.append("nomeVecchio", antiquariato.nome);
        formData.append("linkSito", linkSito.trim());
        formData.append("descrizione", descrizione);
        formData.append("prezzo", prezzo.trim());
        formData.append("urlVecchio", antiquariato.url);

        console.log("Modifica in corso...");

        try {
            // You can write the URL of your server or any other endpoint used for file upload
            const resultModifica = await fetch("https://edizionirebis.com:57447/modifica-antiquariato-immagine", {
                method: "POST",
                headers: { 'Authorization': `Bearer ${auth}`},
                body: formData
            });

            if (resultModifica.status === 200) {
                setErrorSub(false);
                setSuccessSub(true);
            } else {
                setErrorSub(true);
                setSuccessSub(false);
            }

        } catch (error) {
            console.log(error);
            setErrorSub(true);
            setSuccessSub(false);
            return;
        }

        setValueImmagine(undefined)
        setNomeAntiquariato(antiquariato.nome);
        setPrezzo(antiquariato.prezzo ?? "0.00");
        setLinkSito(antiquariato.linkSito ?? "");
        setDescrizione(antiquariato.descrizione ?? "");
        handleModificaClose();
    }

    // MODIFICA SENZA IMMAGINE ANTIQUARIATO

    const modificaAntiquariato = async () => {
        setErrorSub(false);
        setSuccessSub(false);

        setNomeAntiquariatoSub(false);

        const auth = localStorage.getItem('Authorization') || sessionStorage.getItem('Authorization');

        if (nomeAntiquariato === "") {
            setNomeAntiquariatoSub(true);
            setErrorNomeAntiquariatoMessage("Inserire il nome dell'antiquariato...");
            return;
        }
        setNomeAntiquariatoSub(false);
        if (prezzo.length === 0) setPrezzo("0.00");

        const send = { nome: nomeAntiquariato.trim(), 
                       nomeVecchio: antiquariato.nome, prezzo: prezzo.trim(),
                       linkSito: linkSito.trim(), descrizione: descrizione
                    }

        console.log("Modifica in corso...");

        try {
            // You can write the URL of your server or any other endpoint used for file upload
            const resultModifica = await fetch("https://edizionirebis.com:57447/modifica-antiquariato", {
                method: "POST",
                headers: { 'Authorization': `Bearer ${auth}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(send)
            });

            if (resultModifica.status === 200) {
                setErrorSub(false);
                setSuccessSub(true);
            } else {
                setErrorSub(true);
                setSuccessSub(false);
            }

        } catch (error) {
            console.log(error);
            setErrorSub(true);
            setSuccessSub(false);
            return;
        }

        setNomeAntiquariato(antiquariato.nome);
        setPrezzo(antiquariato.prezzo ?? "0.00");
        handleModificaClose();
    }

    // CANCELLA ANTIQUARIATO

    const cancellaAntiquariato = async () => {
        setErrorSub(false);
        setSuccessSub(false);
        
        const auth = localStorage.getItem('Authorization') || sessionStorage.getItem('Authorization');

        try {
            const postObject = {nome: antiquariato.nome, url: antiquariato.url};

            // You can write the URL of your server or any other endpoint used for file upload
            const resultElimina = await fetch("https://edizionirebis.com:57447/elimina-antiquariato", {
                method: "POST",
                headers: { 'Authorization': `Bearer ${auth}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(postObject)
            });

            if (resultElimina.status === 200) {
                setErrorSub(false);
                setSuccessSub(true);
            } else {
                setErrorSub(true);
                setSuccessSub(false);
            }

        } catch (error) {
            console.log(error);
            setErrorSub(true);
            return;
        }
        
        handleCancellaClose();
    }

    function onEnterImage() {
        (refDragImage.current as any).className = 'file-input active';
    }

    function onLeaveImage() {
        (refDragImage.current as any).className = 'file-input'
    }

    return (
    <>
    <div className='align-self-center'>
        <img id={"Antiquariato"+antiquariato.nome} src={antiquariato.url} alt={antiquariato.nome} width="165px" height="200px" className='m-auto d-block antiquariatoCopertina libro'/>
        <div className="mt-2" style={{margin:'auto', maxWidth:'75%'}}>
            <div id={antiquariato.nome} className="nomeAntiquariato">
                <span>{antiquariato.nome}</span>
            </div>
        </div>
        <div className="text-center mt-3">
            <Button onClick={handleModificaShow} variant="outline-secondary" aria-label="Modifica">Modifica</Button>{' '}
            <Button onClick={handleCancellaShow} variant="outline-danger" aria-label="Cancella">Elimina</Button>
        </div>
    </div>
    <Modal show={showModifica} onHide={handleModificaClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Modifica {antiquariato.nome}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <Form onSubmit={(e) => {
                        e.preventDefault();
                        immagine ? modificaAntiquariatoImmagine() : modificaAntiquariato()
                        }
                    }   id="changeAntiquariato">
                    <br />
                    <div className='text-center'>
                        <p className='testoBlu'>ANTEPRIMA:</p>
                        {
                            immagine ?
                            <img id={"Antiquariato"+immagine.name} src={URL.createObjectURL(immagine)} alt={immagine.name} width="165px" height="200px" className='antiquariatoCopertina libro'/>
                            :
                            <img id={"Antiquariato"+antiquariato.nome} src={antiquariato.url} alt={"Antiquariato"+antiquariato.nome} width="165px" height="200px" className='antiquariatoCopertina libro'/>
                        }<br /> <br />
                        <label className="file-input d-inline-grid w-100" htmlFor="file"
                               onDragEnter={onEnterImage} 
                               onDragEnd={onLeaveImage} 
                               onDragLeave={onLeaveImage} 
                               onDragExit={onLeaveImage}
                               onMouseLeave={onLeaveImage}
                               ref={refDragImage}>
                            <div className="drop-zone">
                                <p><b>Inserisci o trascina</b> l'immagine dell'oggetto</p>
                            </div>
                            <input type="file" id="file" 
                            onChange={handleFileChange}
                            accept="image/jpeg"
                            value={valueImmagine}
                            placeholder="Immagine oggetto"
                            />
                        </label>
                    </div>
                    <br /><br />
                    <h5 className='text-danger' style={{display: (errorImmagineSub ? 'initial' : 'none'), marginBottom:10}}>
                        {errorImageMessage}
                    </h5>   


                        <Form.Group className="mb-3" controlId={"formBasicModificaNome"+antiquariato.nome}>
                            <Form.Label>Nome dell'oggetto</Form.Label>
                            <Form.Control isInvalid={errorNomeAntiquariatoSub} type="text" placeholder="Nome dell'oggetto" value={nomeAntiquariato} 
                            onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setNomeAntiquariato(e.target.value)} 
                            }
                            />
                            <Form.Control.Feedback type="invalid">
                                {errorNomeAntiquariatoMessage}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId={"formBasicModificaPrezzo"+antiquariato.nome}>
                            <Form.Label>Prezzo</Form.Label>
                            <Form.Control type="number" step={.01} placeholder="0.00" min="0" value={prezzo} 
                            onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                const str = e.target.value
                                if (str.charAt(str.length - 1) === '.') { return }
                                setPrezzo(str);
                                }
                            } />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicLinkSito">
                            <Form.Label>Link</Form.Label>
                            <Form.Control type="url" placeholder="https://"
                            value={linkSito} 
                            onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setLinkSito(e.target.value)
                                } 
                            }/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicDescrizione">
                            <Form.Label>Descrizione</Form.Label>
                            <Form.Control type="text" as="textarea" rows={descrizione.length > 50 ? 10 : 2} maxLength={15000} placeholder="Descrizione"
                            value={descrizione} 
                            onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setDescrizione(e.target.value);
                                }
                            } />
                        </Form.Group>

                        <Form.Group className="text-end float-end w-50">
                            <Button variant="outline-success" type="submit">
                                Modifica
                            </Button>
                            {' '}
                            <Button variant="outline-secondary" onClick={handleModificaClose}>
                                Chiudi
                            </Button>
                        </Form.Group>
                    </Form>

        </Modal.Body>
      </Modal>

      <Modal show={showCancella} onHide={handleCancellaClose}>
        <Modal.Header closeButton>
          <Modal.Title className="">Sei sicuro di voler cancellare {antiquariato.nome}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="float-end text-end">
                <Button variant="outline-danger" onClick={cancellaAntiquariato}>
                    Cancella
                </Button>
                {' '}
                <Button variant="outline-secondary" onClick={handleCancellaClose}>
                    Chiudi
                </Button>
            </div>
        </Modal.Body>
      </Modal>
    </>
    )
}

export default PannelloChangeAntiquariato;