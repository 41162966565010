import React from "react";
import Sfondo_libri from './images/n_draghi.png'

const ChiSiamo = () => {
    return (
        <div id="Edizioni_Rebis_Home" style={{backgroundImage:'url("'+ Sfondo_libri +'")', backgroundColor:'rgba(255,255,255,1)', backgroundPosition:'center center', backgroundSize:'contain', backgroundRepeat:'no-repeat'}} className='container-fluid p-5'>
		    <div className="row justify-content-center" style={{backgroundColor:'rgba(232,232,232,0.3)', borderRadius:'25px', backdropFilter:'blur(2px)'}}>
                <div className="col-lg-6 align-self-center p-5 text-center" style={{fontFamily:'Abril Display', fontStyle:'normal'}}>
		                <span style={{fontSize:35, color:'rgba(1,65,189,1)'}}>CASA EDITRICE REBIS - Dal 1970 Libri Rari di Scienze Occulte</span><br /><br />
                        <span style={{color:'rgba(221,0,0,1)'}}>SPECIALIZZATA NELLA VENDITA ONLINE DI LIBRI DI ERMETISMO, ESOTERISMO, SCIENZE<br />OCCULTE E DIVINATORIE, MAGIA, TAROCCHI E MAZZI PER CARTOMANZIA ESCLUSIVI.</span><br /><br />
                        <p className="textRobeInformazioni">
                            Le Edizioni Rebis nascono nel 1970 da un progetto di Pier Luca Pierini R., già collaboratore delle maggiori Case 
                            editrici e riviste specializzate dell'epoca - coadiuvato da un gruppo di giovani esoteristi, quale espressione di un 
                            gruppo di orientamento iniziatico tradizionale, attivo in quel periodo in Toscana. Esempio di lineare e concreta 
                            coerenza, la Rebis si propone fin dall'inizio di offrire al pubblico interessato una serie di testi rari o inediti di scienze 
                            occulte, ermetismo, magia, alchimia, cabala, esoterismo, occultismo e arti divinatorie, tutti provenienti direttamente 
                            dal proprio Archivio, che oggi comprende oltre 35.000 volumi a stampa e manoscritti originali, dalla fine del XV 
                            secolo a oggi, relativi alle stesse materie. Un intento serio e un impegno tutt'altro che facili, peraltro mantenuti e 
                            confermati nel tempo, pienamente giustificati e ampiamente apprezzati in un periodo in cui certe materie e discipline 
                            - ancora lontane dalle varie tendenze che ne determinarono la marcata diffusione a partire dalla fine degli anni '70 - 
                            costituivano ancora un'isola elitaria, limitata a pochi e riservati appassionati, ed erano scarsamente rappresentate 
                            nel panorama editoriale e librario italiano e internazionale. I primi titoli sono infatti realizzati per restituire a una 
                            cerchia ristretta di studiosi e amatori alcune opere di fondamentale interesse, quali la prima traduzione italiana dei 
                            manifesti rosacruciani “Fama e Confessio Fraternitatis” e degli inediti “Heptameron” di Pietro D'Abano, “I Simboli 
                            Segreti dei Rosa+Croce” completo delle splendide tavole, il “Liber Umbrarum” e “Le 12 Chiavi dell'Alchimia” di 
                            Basilio Valentino. Seguono le ristampe di testi ormai divenuti introvabili, come “L'Alchimia” di J.Castelot e una serie 
                            di antichi importanti grimoires, i primi ripubblicati in Italia, con ampie e opportune introduzioni, dopo oltre un secolo 
                            dalle edizioni originali, quali “Il Vero Enchiridion”, “Il Drago Rosso”, “La Clavicola del Re Salomone”, “La Scienza 
                            Cabalistica” di Lenain e molti altri ancora. Nel 1977 alla Rebis, già ampiamente conosciuta non solo in Italia, viene 
                            conferito il prestigioso primo premio “Città di Napoli”, patrocinato dall'Amministrazione comunale, per le migliori 
                            pubblicazioni di esoterismo e l'anno successivo, il “Golden Hermes” della autorevole rivista specializzata americana 
                            “Heart Religion News”, per le fedeli ristampe di antichi testi magici. Nello stesso periodo sono pubblicate tutte le 
                            principali opere dell'Abate Julio, autore già largamente stimato e seguito in Francia ma in quegli anni ancora quasi 
                            sconosciuto in Italia, e gli scritti più significativi di Prentice Mulford, il celebre precursore e “padre” del “Pensiero 
                            Positivo”. È del 1982 la prima e unica ristampa integrale in due volumi delle annate complete 1897-98-99 della 
                            importantissima rivista di G.Kremmerz “Il Mondo Secreto”, la prima esplicitamente esoterica pubblicata in Italia: 
                            un'opera colossale di oltre 1300 pagine, alla quale seguono nel tempo molti altri scritti fondamentali dello stesso 
                            insigne ermetista e di suoi discepoli, parallelamente alla pubblicazione delle sue biografie “storiche”, raccolte nel 
                            2011 assieme ad altri contributi documentali inediti, nel volume “Il Sole Arcano”. Le varie collane editoriali si 
                            arricchiscono intanto di nomi tra i più rappresentativi della letteratura occultistica, quali Eliphas Levi, de Guaita, 
                            Papus, Hartmann, Sèdir, Durville, Muchery, Shirley, Bornia, Zingaropoli, Bricaud, Meyrink e molti altri. Negli Anni '80 
                            e '90 si ripropongono altre rare riviste di alto spessore esoterico, ormai esaurite e ricercate da tempo, quali “O' Thanatos”,
                            “Iniziazione” e “La Fenice e Ibis”, oltre a una serie inedita di trattati sulla Magia degli Angeli, sulla rituaria 
                            dedicata agli Incensi e alle Candele, sulla pratica operativa e talismanica dei Rosa+Croce, sulle Piante Magiche, la 
                            Magia delle Fate e sulla Tradizione Divinatoria, con particolare attenzione alla Cartomanzia. In relazione a 
                            quest'ultimo interessantissimo settore, iniziano inoltre le pubblicazioni di quella che ormai è diventata una notevole e cospicua collezione, 
                            particolarmente curata, di rari e affascinanti mazzi di carte per cartomanzia e di antichi 
                            bellissimi Tarocchi, tutti corredati da utili testi esplicativi. Dalla metà degli Anni '80, periodo in cui Pier Luca Pierini 
                            inaugura il primo museo in Italia interamente dedicato alle Scienze Occulte (attualmente in ristrutturazione), si 
                            affianca inoltre alla Rebis la sigla editoriale “Il Gatto Nero”, e proprio sotto questa insegna vede la luce, preceduta 
                            da articoli introduttivi dello stesso Autore su riviste specializzate, la prima edizione italiana dell'opera che ha dato 
                            fama e popolarità postume al celebrato scrittore, artista e ricercatore Charles Godfrey Leland: “Aradia, o il Vangelo 
                            delle Streghe Italiane”, alla quale seguono le prime traduzioni integrali italiane delle sue maggiori opere “Etruscan Roman Remains”
                            e “Legends of Florence”, con il titolo “Il Tesoro delle Streghe” (in due volumi) e “Firenze Arcana”.
                            Tra la fine degli Anni '90 e i primi del nuovo secolo, appaiono invece le prime traduzioni di altre eccellenti opere della 
                            tradizione magica, quali il monumentale “La Chiave della Magia Nera” di Stanislao de Guaita, il magnifico “Magia Cerimoniale”
                             del Waite e il celeberrimo “Il Magus” di Francis Barrett, tutte tratte dalle prime edizioni originali delle medesime,
                             oltre a opere di grande interesse sulla magia operativa dei Rosa+Croce e sulla teobotanica, come “Il Sacramentario Segreto della Suprema Magia Rosa+Croce”,
                             “Il Potere delle Piante Magiche” di Sedir e “La Magica Mandragora”,
                             l'opera più completa esistente sulla misteriosa pianta dai poteri leggendari.
                             Contemporaneamente prende vita una collezione specifica di opere di particolare pregio e valore bibliografico, dal titolo “I Gioielli”, fiore 
                             all'occhiello della Casa editrice e attualmente considerata tra le più interessanti e qualificate collane editoriali 
                             esistenti al mondo, anche in ambito collezionistico. In questa prestigiosa serie, rigorosamente curata nella stampa, 
                             nella scelta della carta e delle lussuose legature in tela pregiata rifinita in oro, sono apparsi fino ad oggi titoli inediti o 
                             di assoluta rarità, quali “Il Tesoro Magico del Re Salomone”, “La Spada Magica di Mosè”, “Il Libro Magico degli Spiriti Supremi”,
                             “Il Libro Magico dei Sette Draghi”, “Lo Scrigno dei Segreti del Re Salomone”, “Il Libro della Magia Cerimoniale”,
                             “Il Magus”, “Il Libro dei Vampiri”, “Il Vero Libro delle Ombre”. “Il Libro Magico dei 72 Angeli di Salomone”,
                             il bellissimo “Sepher Elohim” e “Il Libro Magico dei 72 Angeli del Re Salomone”. Dal 2000 a oggi 
                             moltissimi altri titoli sono stati realizzati e riproposti, tra i quali possiamo ricordare l'importante raccolta di antichi e 
                             rari testi magici riuniti nei vari volumi di “Magia Segreta”, giunta ormai all'ottavo volume. Ma l'opera più originale e 
                             creativa di questo periodo è da considerarsi indubbiamente “Elixir - Scritti della Tradizione Iniziatica e Arcana”: una 
                             rivista - definita dal noto studioso, già direttore di Rai 2, Gabriele La Porta, “il top dell'Esoterismo” - apprezzata 
                             anche in ambito internazionale con recensioni decisamente lusinghiere, che costituisce in realtà a un libro vero e 
                             proprio, nella quale sono riuniti saggi, monografie, studi e ricerche di altissimo livello e in gran parte inediti, relativi 
                             agli aspetti più profondi o sconosciuti di Ermetismo, Alchimia, Magia, Esoterismo e materie affini. Da notare che la 
                             Casa editrice Rebis ha voluto mantenere sempre, per una scelta precisa dell'Editore, una rigorosa impostazione di 
                             coerente fedeltà alle materie trattate, un'impronta e uno stile artigianali qualitativamente mirati, rivolti direttamente e 
                             prevalentemente a un pubblico selezionato di autentici appassionati e cultori delle stesse discipline, nonché alle 
                             Librerie specializzate in questi settori. I riconoscimenti e gli encomi rivolti alle Edizioni Rebis, dall'inizio a oggi, da 
                             parte di enti pubblici e privati, biblioteche, associazioni, librerie, studiosi, ricercatori e collezionisti, sono 
                             decisamente innumerevoli e da molti anni hanno ormai superato gli stessi confini nazionali ed europei.</p>
                </div>
			</div>
        </div>
    );
}

export default ChiSiamo;