import React from "react";
import OggettoAntiquariato from "./componenti/OggettoAntiquariato";
import Matonti from './images/alessandro-matonti-ObNNop-eA-4.png'
import { antiquariato } from "./componenti/FetchingData";

const Antiquariato = ({antiquariati} : {antiquariati : antiquariato[]}) => {

    return (
        <>
    <img src={Matonti} alt="header" className="w-100"/>
    <div id="Edizioni_Rebis_Home" style={{backgroundColor:'rgba(232,232,232,1)', backgroundSize:'100% 100%', backgroundPosition:'center center'}} className='container-fluid'>
        <div className="row justify-content-center">
            <div className="text-center fs-1 fw-bolder text-decoration-underline mt-3" style={{fontFamily:'Abril Display', color:'rgba(221,0,0,1)'}}>Antiquariato</div>
            <div className='col-lg-12 align-self-center p-3 p-lg-5' style={{backgroundColor:'rgba(232,232,232,1)'}}>
                <div id="Libri_ultime_novit" className='row justify-content-center'>
                            {
                                antiquariati.map((elem: antiquariato, index) => {
                                    return <OggettoAntiquariato key={index} antiquariato={elem} numero={index} numRow={3} />
                                })
                            }	
                </div>
            </div>
        </div>
    </div>
    </>
    )
}

export default Antiquariato;