import React, { useState, useRef, useEffect } from 'react';
import { libro } from "./FetchingData";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import LibroUnk from '../images/LibroUnk.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCheck, faSquareMinus, faSquareXmark } from '@fortawesome/free-solid-svg-icons'

const FormAggiungiLibro = ({setErrorSub, setSuccessSub, libri} : {setErrorSub : React.Dispatch<React.SetStateAction<boolean>>, setSuccessSub : React.Dispatch<React.SetStateAction<boolean>>, libri: libro[]}) => {
    const [immagine, setImmagine] = useState<File | null>(null);
    const [valueImmagine, setValueImmagine] = useState<string | undefined>(undefined);
    const [nomeLibro, setNomeLibro] = useState("");
    const [catalogoSelezionato, setCatalogoSelezionato] = useState("0");
    const [prezzo, setPrezzo] = useState("0.00");
    const [linkSito, setLinkSito] = useState("");
    const [descrizione, setDescrizione] = useState("");
    const [isNovita, setIsNovita] = useState(true);

    const [showIfNameExists, setShowIfNameExists] = useState(false);
    const [colorIfNameExists, setColorIfNameExists] = useState("black")
    const [iconIfNameExists, setIconIfNameExists] = useState(faSquareMinus);

    const [errorImageMessage, setErrorImageMessage] = useState("");
    const [errorNomeLibroMessage, setErrorNomeLibroMessage] = useState("");
    const [errorCatalogoSelezionatoMessage, setErrorCatalogoSelezionatoMessage] = useState("");
    const [errorImmagineSub, setErrorImmagineSub] = useState(false);
    const [errorNomeLibroSub, setNomeLibroSub] = useState(false);
    const [errorCatalogoSelezionatoSub, setCatalogoSelezionatoSub] = useState(false);
    
    const refDragImage = useRef(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.files) {
            setValueImmagine(undefined);
            setImmagine(e.target.files[0]);
        }
    };

    const aggiungiLibro = async () => {
        setErrorImmagineSub(false);
        setNomeLibroSub(false);
        setCatalogoSelezionatoSub(false);
        setSuccessSub(false);
        setErrorSub(false);

        const auth = localStorage.getItem('Authorization') || sessionStorage.getItem('Authorization');

        if (!immagine) {
            setErrorImmagineSub(true);
            setErrorImageMessage("Inserire l'immagine del libro...");
            return;
        }
        if (immagine.type !== "image/png" && immagine.type !== "image/jpeg") {
            setErrorImmagineSub(true);
            setErrorImageMessage("File invalido...");
            return;
        }
        setErrorImmagineSub(false);
        if (nomeLibro === "") {
            setNomeLibroSub(true);
            setErrorNomeLibroMessage("Inserire il nome del libro...");
            return;
        }
        setNomeLibroSub(false);
        if (!catalogoSelezionato || catalogoSelezionato === "0") {
            setCatalogoSelezionatoSub(true);
            setErrorCatalogoSelezionatoMessage("Selezionare il catalogo del libro...");
            return;
        }
        setCatalogoSelezionatoSub(false);
        if (prezzo.length === 0) setPrezzo("0.00");
        
        console.log("Aggiunta in corso...");
    
        const formData = new FormData();
        formData.append("file", immagine);
        formData.append("nome", nomeLibro.trim());
        formData.append("catalogo", catalogoSelezionato);
        formData.append("linkSito", linkSito.trim());
        formData.append("descrizione", descrizione);
        formData.append("prezzo", prezzo.trim());
        formData.append("isnovita", isNovita.toString());

        try {
            // You can write the URL of your server or any other endpoint used for file upload
            const resultImmagine = await fetch("https://edizionirebis.com:57447/inserisci-libro", {
                method: "POST",
                headers: { 'Authorization': `Bearer ${auth}` },
                body: formData
            });

            if (resultImmagine.status === 200) {
                const data = await resultImmagine.json();
                console.log(data);
                setErrorSub(false);
                setSuccessSub(true);
            } else {
                setErrorSub(true);
                setSuccessSub(false);
            }

        } catch (error) {
            console.log(error);
            setSuccessSub(false)
            setErrorSub(true);
        }

        setValueImmagine(undefined)
        setNomeLibro("");
        setCatalogoSelezionato("0");
        setImmagine(null);
        setPrezzo("0.00");
        setIsNovita(false);

        setDescrizione("");
        setLinkSito("");
    }

    useEffect(() => {
        setShowIfNameExists(nomeLibro.length > 0);
        setColorIfNameExists("black");
        setIconIfNameExists(faSquareMinus);
    }, [nomeLibro])

    function onEnterImage() {
        (refDragImage.current as any).className = 'file-input active';
    }

    function onLeaveImage() {
        (refDragImage.current as any).className = 'file-input'
    }

    function checkIfNameExists() {
        if(libri.find((l) => l.nome.toLowerCase() === nomeLibro.trim().toLowerCase())) {
            setColorIfNameExists("#ef0606")
            setIconIfNameExists(faSquareXmark);
        }
        else {
            setColorIfNameExists("limegreen")
            setIconIfNameExists(faSquareCheck);
        } 
    }

    return (
        <Form className="mt-3" onSubmit={(e) => {
            e.preventDefault();
            aggiungiLibro();
            }
        }>
            <br/><br/>
                    <div className='text-center'>
                        <p className='testoRosso'>ANTEPRIMA:</p>
                        {
                            immagine ?
                            <img id={"Libro"+immagine.name} src={URL.createObjectURL(immagine)} alt={immagine.name} width="165px" height="200px" className='libroCopertina libro'/>
                            :
                            <img id={"LibroUnknown"} src={LibroUnk} alt={"LibroUnknown"} width="165px" height="200px" className='libroCopertina libro'/>
                        }<br/> <br/>
                        <label className="file-input d-inline-grid w-100" htmlFor="file"
                               onDragEnter={onEnterImage} 
                               onDragEnd={onLeaveImage} 
                               onDragLeave={onLeaveImage} 
                               onDragExit={onLeaveImage} 
                               onMouseLeave={onLeaveImage}
                               ref={refDragImage}>
                            <div className="drop-zone">
                                <p><b>Inserisci o trascina</b> l'immagine del libro</p>
                            </div>
                            <input type="file" id="file" 
                            onChange={handleFileChange}
                            accept="image/*"
                            value={valueImmagine}
                            placeholder="Immagine libro"
                            />
                        </label>
                    </div>
                    <br/><br/>
                    <h5 className='text-danger' style={{display: (errorImmagineSub ? 'initial' : 'none'), marginBottom:10}}>
                        {errorImageMessage}
                    </h5>

            <Form.Group className="mb-3" controlId="formBasicNome">
                <Form.Label>Nome del libro</Form.Label>

                <div className="showPassContainer">
                    <FontAwesomeIcon icon={iconIfNameExists} onClick={checkIfNameExists} style={{color:colorIfNameExists, visibility:(showIfNameExists ? "visible" : "hidden")}}/>
                </div>
                
                <Form.Control isInvalid={errorNomeLibroSub} type="text" placeholder="Nome del libro" value={nomeLibro} 
                onChange={(e) => {
                    setNomeLibro(e.target.value)
                } 
                }
                />
				

                <Form.Control.Feedback type="invalid">
                    {errorNomeLibroMessage}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCatalogo">
                <Form.Label>Catalogo</Form.Label>
                    <Form.Select isInvalid={errorCatalogoSelezionatoSub} aria-label="Seleziona il catalogo" 
                    onChange={(e) => {
                        setCatalogoSelezionato(e.target.value)}
                    }
                    value={catalogoSelezionato}
                    >
                        <option value="0">Apri il menu cataloghi</option>
                        <option value="Abate Julio">Abate Julio</option>
                        <option value="Esoterismo">Esoterismo</option>
                        <option value="Alchimia">Alchimia</option>
                        <option value="Grimoires">Grimoires</option>
                        <option value="Angeli">Angeli</option>
                        <option value="Magia Pratica">Magia Pratica</option>
                        <option value="Arti Divinatorie">Arti Divinatorie</option>
                        <option value="Magia Rituale">Magia Rituale</option>
                        <option value="Cabala">Cabala</option>
                        <option value="Opere Pierini">Opere Pier Luca Pierini R.</option>
                        <option value="Colonna I Gioielli">Colonna I Gioielli</option>
                        <option value="Rivista Elixir">Rivista Elixir</option>
                        <option value="Ermetismo">Ermetismo</option>
                        <option value="Rosa † Croce">Rosa † Croce</option>
                    </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errorCatalogoSelezionatoMessage}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPrezzo">
                <Form.Label>Prezzo</Form.Label>
                <Form.Control type="number" step={.01} placeholder="0.00" min="0" value={prezzo} 
                onChange={(e) => {
                    const str = e.target.value
                    if (str.charAt(str.length - 1) === '.') { return }
                    setPrezzo(str);
                    }
                } />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicLinkSito">
                <Form.Label>Link</Form.Label>
                <Form.Control type="url" placeholder="https://"
                value={linkSito} 
                onChange={(e) => {
                    setLinkSito(e.target.value)
                    } 
                }/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicDescrizione">
                <Form.Label>Descrizione</Form.Label>
                <Form.Control type="text" as="textarea" rows={descrizione.length > 50 ? 10 : 2} maxLength={15000} placeholder="Descrizione"
                value={descrizione} 
                onChange={(e) => {
                    setDescrizione(e.target.value);
                    }
                } />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicIsNovita">
                <Form.Check type="checkbox" label="E' una novità?" defaultChecked={isNovita} onChange={() => setIsNovita(!isNovita)}/>
            </Form.Group>

            <Button variant="outline-primary" className="btnFormSend w-100" type="submit">
                Aggiungi
            </Button>
        </Form>
    )
}

export default FormAggiungiLibro;