import React, { useRef, useState } from "react";
import { tarocco } from "./FetchingData";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import Form from "react-bootstrap/esm/Form";

const PaginaInformazioniTarocco = ({tarocco, setErrorSub, setSuccessSub} : {tarocco: tarocco, setErrorSub : React.Dispatch<React.SetStateAction<boolean>>, setSuccessSub : React.Dispatch<React.SetStateAction<boolean>>}) => {

    const [showModifica, setShowModifica] = useState(false);

    const handleModificaClose = () => setShowModifica(false);
    const handleModificaShow = () => setShowModifica(true);

    const [showCancella, setShowCancella] = useState(false);

    const handleCancellaClose = () => setShowCancella(false);
    const handleCancellaShow = () => setShowCancella(true);


    const [errorImmagineSub, setErrorImmagineSub] = useState(false);
    const [errorNomeTaroccoSub, setNomeTaroccoSub] = useState(false);
    const [errorCatalogoSelezionatoSub, setCatalogoSelezionatoSub] = useState(false);

    const [errorImageMessage, setErrorImageMessage] = useState("");
    const [errorNomeTaroccoMessage, setErrorNomeTaroccoMessage] = useState("");
    const [errorCatalogoSelezionatoMessage, setErrorCatalogoSelezionatoMessage] = useState("");

    const [immagine, setImmagine] = useState<File | null>(null);
    const [valueImmagine, setValueImmagine] = useState<string | undefined>(undefined);
    const [nomeTarocco, setNomeTarocco] = useState(tarocco.nome);
    const [catalogoSelezionato, setCatalogoSelezionato] = useState(tarocco.catalogo);
    const [prezzo, setPrezzo] = useState(tarocco.prezzo ?? "0,00");

    const [linkSito, setLinkSito] = useState(tarocco.linkSito ?? "");
    const [descrizione, setDescrizione] = useState(tarocco.descrizione ?? "");
    const [isNovita, setIsNovita] = useState(tarocco.isnovita ? true : false);

    const refDragImage = useRef(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.files) {
          setValueImmagine(undefined);
          setImmagine(e.target.files[0]);
        }
      };

    // MODIFICA TAROCCO CON IMMAGINE

    const modificaTaroccoImmagine = async () => {
        setErrorSub(false);
        setSuccessSub(false);

        setErrorImmagineSub(false);
        setNomeTaroccoSub(false);
        setCatalogoSelezionatoSub(false);

        const auth = localStorage.getItem('Authorization') || sessionStorage.getItem('Authorization');

        if (!immagine) {
            setErrorImmagineSub(true);
            setErrorImageMessage("Inserire l'immagine dei tarocchi o cartomanzia...");
            return;
        }
        if (immagine && immagine.type !== "image/png" && immagine.type !== "image/jpeg") {
            setErrorImmagineSub(true);
            setErrorImageMessage("File invalido...");
            return;
        }
        setErrorImmagineSub(false);
        if (nomeTarocco === "") {
            setNomeTaroccoSub(true);
            setErrorNomeTaroccoMessage("Inserire il nome dei tarocchi o cartomanzia...");
            return;
        }
        setNomeTaroccoSub(false);
        if (!catalogoSelezionato || catalogoSelezionato === "0") {
            setCatalogoSelezionatoSub(true);
            setErrorCatalogoSelezionatoMessage("Selezionare il catalogo dei tarocchi o cartomanzia...");
            return;
        }
        setCatalogoSelezionatoSub(false);
        if (prezzo.length === 0) setPrezzo("0.00");

        const formData = new FormData();
        formData.append("file", immagine);
        formData.append("nome", nomeTarocco.trim());
        formData.append("catalogo", catalogoSelezionato);
        formData.append("nomeVecchio", tarocco.nome);
        formData.append("catalogoVecchio", tarocco.catalogo);
        formData.append("linkSito", linkSito.trim());
        formData.append("descrizione", descrizione);
        formData.append("prezzo", prezzo.trim());
        formData.append("urlVecchio", tarocco.url);
        formData.append("isnovita", isNovita.toString());

        console.log("Modifica in corso...");

        try {
            // You can write the URL of your server or any other endpoint used for file upload
            const resultModifica = await fetch("https://edizionirebis.com:57447/modifica-tarocco-immagine", {
                method: "POST",
                headers: { 'Authorization': `Bearer ${auth}`},
                body: formData
            });

            if (resultModifica.status === 200) {
                const data = await resultModifica.json();
                console.log(data);
                setErrorSub(false);
                setSuccessSub(true);
            } else {
                setErrorSub(true);
                setSuccessSub(false);
            }

        } catch (error) {
            console.log(error);
            setErrorSub(true);
            setSuccessSub(false);
            return;
        }

        setValueImmagine(undefined)
        setNomeTarocco(tarocco.nome);
        setCatalogoSelezionato(tarocco.catalogo);
        setPrezzo(tarocco.prezzo ?? "0.00");
        setLinkSito(tarocco.linkSito ?? "");
        setDescrizione(tarocco.descrizione ?? "");
        setIsNovita(tarocco.isnovita ? true : false);
        handleModificaClose();
        window.location.reload();
    }

    // MODIFICA SENZA IMMAGINE TAROCCO

    const modificaTarocco = async () => {
        setErrorSub(false);
        setSuccessSub(false);

        setNomeTaroccoSub(false);
        setCatalogoSelezionatoSub(false);

        const auth = localStorage.getItem('Authorization') || sessionStorage.getItem('Authorization');

        if (nomeTarocco === "") {
            setNomeTaroccoSub(true);
            setErrorNomeTaroccoMessage("Inserire il nome dei tarocchi o cartomanzia...");
            return;
        }
        setNomeTaroccoSub(false);
        if (!catalogoSelezionato || catalogoSelezionato === "0") {
            setCatalogoSelezionatoSub(true);
            setErrorCatalogoSelezionatoMessage("Selezionare il catalogo dei tarocchi o cartomanzia...");
            return;
        }
        setCatalogoSelezionatoSub(false);
        if (prezzo.length === 0) setPrezzo("0.00");

        const send = { nome: nomeTarocco.trim(), catalogo: catalogoSelezionato, 
                       nomeVecchio: tarocco.nome, catalogoVecchio: tarocco.catalogo, prezzo: prezzo.trim(),
                       linkSito: linkSito.trim(), descrizione: descrizione, isnovita: isNovita.toString()
                    }

        console.log("Modifica in corso...");

        try {
            // You can write the URL of your server or any other endpoint used for file upload
            const resultModifica = await fetch("https://edizionirebis.com:57447/modifica-tarocco", {
                method: "POST",
                headers: { 'Authorization': `Bearer ${auth}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(send)
            });

            if (resultModifica.status === 200) {
                const data = await resultModifica.json();
                console.log(data);
                setErrorSub(false);
                setSuccessSub(true);
            } else {
                setErrorSub(true);
                setSuccessSub(false);
            }

        } catch (error) {
            console.log(error);
            setErrorSub(true);
            setSuccessSub(false);
            return;
        }

        setNomeTarocco(tarocco.nome);
        setCatalogoSelezionato(tarocco.catalogo);
        setPrezzo(tarocco.prezzo ?? "0.00");
        setIsNovita(tarocco.isnovita ? true : false);
        handleModificaClose();
        window.location.reload();
    }

    // CANCELLA TAROCCO

    const cancellaTarocco = async () => {
        setErrorSub(false);
        setSuccessSub(false);
        
        const auth = localStorage.getItem('Authorization') || sessionStorage.getItem('Authorization');

        try {
            const postObject = {nome: tarocco.nome, catalogo: tarocco.catalogo, url: tarocco.url};

            // You can write the URL of your server or any other endpoint used for file upload
            const resultElimina = await fetch("https://edizionirebis.com:57447/elimina-tarocco", {
                method: "POST",
                headers: { 'Authorization': `Bearer ${auth}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(postObject)
            });

            if (resultElimina.status === 200) {
                const data = await resultElimina.json();
                console.log(data);
                setErrorSub(false);
                setSuccessSub(true);
            } else {
                setErrorSub(true);
                setSuccessSub(false);
            }

        } catch (error) {
            console.log(error);
            setErrorSub(true);
            return;
        }
        
        handleCancellaClose();
    }

    function onEnterImage() {
        (refDragImage.current as any).className = 'file-input active';
    }

    function onLeaveImage() {
        (refDragImage.current as any).className = 'file-input'
    }

    return (
    <>
    <div className='align-self-center'>
        <img id={"Tarocco"+tarocco.nome+tarocco.catalogo} src={tarocco.url} alt={tarocco.nome} width="165px" height="200px" className='m-auto d-block libroCopertina libro'/>
        <div className="mt-2" style={{margin:'auto', maxWidth:'75%'}}>
            <div id={tarocco.nome} style={{borderBottom:'2px solid rgba(221,0,0,1)'}} className="nomeLibro">
                <span>{tarocco.nome}</span>
            </div>
            <div id={ tarocco.catalogo } className="catalogoLibro">
                <span>{ tarocco.catalogo }</span>
            </div>
        </div>
        <div className="text-center mt-3">
            <Button onClick={handleModificaShow} variant="outline-secondary" aria-label="Modifica">Modifica</Button>{' '}
            <Button onClick={handleCancellaShow} variant="outline-danger" aria-label="Cancella">Elimina</Button>
        </div>
    </div>
    <Modal show={showModifica} onHide={handleModificaClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Modifica {tarocco.nome}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <Form onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        immagine ? modificaTaroccoImmagine() : modificaTarocco()
                        }
                    }>
                    <br />
                    <div className='text-center'>
                        <p className='testoRosso'>ANTEPRIMA:</p>
                        {
                            immagine ?
                            <img id={"Tarocco"+immagine.name} src={URL.createObjectURL(immagine)} alt={immagine.name} width="165px" height="200px" className='libroCopertina libro'/>
                            :
                            <img id={"Tarocco"+tarocco.nome+tarocco.catalogo} src={tarocco.url} alt={"Tarocco"+tarocco.nome+tarocco.catalogo} width="165px" height="200px" className='libroCopertina libro'/>
                        }<br /> <br />
                        <label className="file-input d-inline-grid w-100" htmlFor="file"
                               onDragEnter={onEnterImage} 
                               onDragEnd={onLeaveImage} 
                               onDragLeave={onLeaveImage} 
                               onDragExit={onLeaveImage}
                               onMouseLeave={onLeaveImage}
                               ref={refDragImage}>
                            <div className="drop-zone">
                                <p><b>Inserisci o trascina</b> l'immagine dei tarocchi o cartomanzia</p>
                            </div>
                            <input type="file" id="file" 
                            onChange={handleFileChange}
                            accept="image/jpeg"
                            value={valueImmagine}
                            placeholder="Immagine tarocchi"
                            />
                        </label>
                    </div>
                    <br /><br />
                    <h5 className='text-danger' style={{display: (errorImmagineSub ? 'initial' : 'none'), marginBottom:10}}>
                        {errorImageMessage}
                    </h5>   


                        <Form.Group className="mb-3" controlId={"formBasicModificaNome"+tarocco.nome+tarocco.catalogo}>
                            <Form.Label>Nome del tarocco</Form.Label>
                            <Form.Control isInvalid={errorNomeTaroccoSub} type="text" placeholder="Nome dei tarocchi" value={nomeTarocco} 
                            onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setNomeTarocco(e.target.value)} 
                            }
                            />
                            <Form.Control.Feedback type="invalid">
                                {errorNomeTaroccoMessage}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId={"formBasicModificaCatalogo"+tarocco.nome+tarocco.catalogo}>
                            <Form.Label>Catalogo</Form.Label>
                                <Form.Select isInvalid={errorCatalogoSelezionatoSub} aria-label="Seleziona il catalogo" 
                                onChange={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setCatalogoSelezionato(e.target.value)
                                }} value={catalogoSelezionato}
                                >
                                    <option value="Tarocchi">Tarocchi</option>
                                    <option value="Cartomanzia">Cartomanzia</option>
                                </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {errorCatalogoSelezionatoMessage}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId={"formBasicModificaPrezzo"+tarocco.nome+tarocco.catalogo}>
                            <Form.Label>Prezzo</Form.Label>
                            <Form.Control type="number" step={.01} placeholder="0.00" min="0" value={prezzo} 
                            onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                const str = e.target.value
                                if (str.charAt(str.length - 1) === '.') { return }
                                setPrezzo(str);
                                }
                            } />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId={"formBasicLinkSito"+tarocco.nome+tarocco.catalogo}>
                            <Form.Label>Link</Form.Label>
                            <Form.Control type="url" placeholder="https://"
                            value={linkSito} 
                            onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setLinkSito(e.target.value)
                                } 
                            }/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId={"formBasicDescrizione"+tarocco.nome+tarocco.catalogo}>
                            <Form.Label>Descrizione</Form.Label>
                            <Form.Control type="text" as="textarea" rows={descrizione.length > 50 ? 10 : 2} maxLength={15000} placeholder="Descrizione"
                            value={descrizione} 
                            onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setDescrizione(e.target.value);
                                }
                            } />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId={"formBasicIsNovita"+tarocco.nome+tarocco.catalogo}>
                            <Form.Check type="checkbox" label="E' una novità?" defaultChecked={isNovita} onChange={() => setIsNovita(!isNovita)}/>
                        </Form.Group>

                        <Form.Group className="text-end float-end w-50">
                            <Button variant="outline-success" type="submit">
                                Modifica
                            </Button>
                            {' '}
                            <Button variant="outline-secondary" onClick={handleModificaClose}>
                                Chiudi
                            </Button>
                        </Form.Group>
                    </Form>

        </Modal.Body>
      </Modal>

      <Modal show={showCancella} onHide={handleCancellaClose}>
        <Modal.Header closeButton>
          <Modal.Title className="">Sei sicuro di voler cancellare {tarocco.nome}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="float-end text-end">
                <Button variant="outline-danger" onClick={cancellaTarocco}>
                    Cancella
                </Button>
                {' '}
                <Button variant="outline-secondary" onClick={handleCancellaClose}>
                    Chiudi
                </Button>
            </div>
        </Modal.Body>
      </Modal>
    </>
    )
}

export default PaginaInformazioniTarocco;