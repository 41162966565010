import React from "react"
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FiltroCerca = ({filtro, setFiltro} : {filtro : string[], setFiltro : React.Dispatch<React.SetStateAction<string[]>>}) => {

    return (
    <div className="search-box mt-3">
        <button className="btn-search"><FontAwesomeIcon icon={faSearch} /></button>
        <input type="text" className="input-search" placeholder="Filtra per nome..."
        value={filtro.join(" ")}
        onChange={(e) => {
                let frase = e.target.value;
                if (frase[frase.length-1] === '+') frase = frase.slice(0, -1) + "†";

                setFiltro(frase.split(" "))
            }
        }
        />
    </div>
    )
}

export default FiltroCerca;