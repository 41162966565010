import React, { useEffect } from "react";
import checkLogin from "./componenti/loginChecker";
import { useNavigate } from 'react-router-dom';
import Sfondo_libri from './images/Sfondo_libri.png';

const Aggiungi = () => {

    const navigate = useNavigate();

    useEffect(() => {
        checkLogin().then((res) => !res ? navigate("/accediAdmin") : null)
    }, [navigate])

    function changePath(path) {
        navigate(path);
    }

    return (
        <div id="Edizioni_Rebis_Home" style={{backgroundImage:'url("'+ Sfondo_libri +'")'}} className='container-fluid p-5'>
		<div className="row justify-content-center">
			<div className='col-lg-8 align-self-center p-5 justify-content-center' style={{backgroundColor:'rgba(255,255,255,0.3)', borderRadius:'25px', backdropFilter:'blur(50px)'}}>
                <h1 className='display-3 mb-5'>
                    Seleziona la pagina
                </h1>
                <div className="row text-center m-xl-5" style={{border:'3px solid rgba(255,255,255,1)'}}>
                    <div className="col-xl-6" style={{borderBottom:'3px solid rgba(255,255,255,1)',borderRight:'3px solid rgba(255,255,255,1)'}}>
                        <span className="scrittaPaginaAggiungi" onClick={() => changePath("/aggiungiLibri")}>Libri</span>
                    </div>
                    <div className="col-xl-6" style={{borderBottom:'3px solid rgba(255,255,255,1)'}}>
                        <span className="scrittaPaginaAggiungi" onClick={() => changePath("/aggiungiAntiquariati")}>Antiquariati</span>
                    </div>
                    <div className="mt-xl-5 mb-xl-5" style={{borderBottom:'3px solid rgba(255,255,255,1)',borderTop:'3px solid rgba(255,255,255,1)'}}>
                        <span className="scrittaPaginaAggiungi" onClick={() => changePath("/aggiungiTarocchiECartomanzie")}>Tarocchi e Cartomanzie</span>
                    </div>
                    <div className="col-xl-6" style={{borderTop:'3px solid rgba(255,255,255,1)', borderRight:'3px solid rgba(255,255,255,1)'}}>
                        <span className="scrittaPaginaAggiungi" onClick={() => changePath("/aggiungiNotizie")}>Notizie, Articoli e Info</span>
                    </div>
                    <div className="col-xl-6" style={{borderTop:'3px solid rgba(255,255,255,1)'}}>
                        <span className="scrittaPaginaAggiungi" onClick={() => changePath("/aggiungiMuseo")}>Museo</span>
                    </div>
                </div>
        	</div>
		</div>
	</div>
    );
}

export default Aggiungi;