import React, { useState, useRef, useEffect } from 'react';
import './Home.css';
import rebis_x_mosaico_definitivo from './images/rebis_x_mosaico_definitivo.png';
import rebis_x_mosaico_definitivo_cn from './images/rebis_x_mosaico_definitivo_cn.png';
import { Outlet, useLocation } from "react-router-dom";
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faHouse, faSearch } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";

//NAVBAR
const Navbar = () => {

	const refCerca = useRef(null);
	const [scrittaCatalogo, setScrittaCatalogo] = useState('Cerca nel catalogo')
	const [viewBurger, setViewBurger] = useState('none');
	const [checkPage, setCheckPage] = useState("");
	const navigate = useNavigate();
	const location = useLocation();
	const prefix = "Edizioni Rebis";

	function catalogoChange(event: React.ChangeEvent<HTMLInputElement>) {
		const value = event.target.value;
		const newValue = value.charAt(0).toUpperCase() + value.slice(1);
		setScrittaCatalogo(newValue);
	}

	function clickSearch(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
		e.preventDefault();
		if (scrittaCatalogo === 'Cerca nel catalogo') {
			setScrittaCatalogo("");
		}
	}

	function blurSearch(e: React.FocusEvent<HTMLInputElement, Element>) {
		e.preventDefault();
		if (scrittaCatalogo === '') {
			setScrittaCatalogo("Cerca nel catalogo");
		}
	}

	const routeNav = (path : string) =>{
		navigate(path);
	}

	useEffect(() => {
		let pathname : string = location.pathname.replace('categorie/', '').replace('/', '').replace('?', '').replace('-', ' ').replaceAll("%20", ' ');
		pathname = pathname.charAt(0).toUpperCase() + pathname.slice(1);
		if (pathname === "") pathname = "Home";
		
		document.title = `${prefix} - ${pathname.includes("/") ? pathname.split("/")[1] : pathname}`
		let path = "";
		let pathSplitted = location.pathname.split("/");
		if (pathSplitted[1] === "ricerca") path = pathSplitted[2];
		else path = pathSplitted[1];

		switch(path) {
			case ("categorie"):
				setCheckPage("libri")
				break;
			case ("libri"):
				setCheckPage("libri")
				break;
			case ("antiquariato"):
				setCheckPage("antiquariati")
				break;
			case ("antiquariati"):
				setCheckPage("antiquariati")
				break;
			case ("notizie"):
				setCheckPage("notizie")
				break;
			case ("museo"):
				setCheckPage("museo")
				break;
			case ("tarocchi-e-cartomanzia"):
				setCheckPage("tarocchi")
				break;
			case ("tarocchi"):
				setCheckPage("tarocchi")
				break;
			default:
				setCheckPage("tutto")
				break;
		}

		setScrittaCatalogo('Cerca nel catalogo');
		if (refCerca && refCerca.current) refCerca.current.blur();
		setViewBurger('none');
		
		window.scrollTo(0, 0);
	}, [location])

  return (
	<>
	<div id="Navbar" style={{zIndex:10}}>
		
		<div id="Navbar_ci" style={{backgroundColor:'rgba(1,65,189,1)'}} className='container-fluid'>
			<div className="row justify-content-center">
				<div id="Logo_Edizioni_Rebis" className='col-lg-4 col-12 mb-md-0 mb-2'>
					<div id="EDIZIONI_REBIS" className='d-inline-block' style={{cursor:'pointer'}} onClick={() => routeNav("")}>
						<img id="rebis_x_mosaico_definitivo_cn" alt='rebis_x_mosaico_definitivo_cn' src={rebis_x_mosaico_definitivo_cn} className='align-text-md-bottom align-text-center' />
						<span>EDIZIONI REBIS</span>
					</div>
				</div>
				<div className={"container col-lg-4 col-12 m-lg-0"}>
					<div className='cercaPannello d-flex'>
							<FontAwesomeIcon icon={faSearch} className='me-2 ms-3 align-self-center' style={{fontSize:'larger'}}/>
							<input ref={refCerca} id="catalogoInput" name="catalogoInput" value={scrittaCatalogo} className='align-self-center w-100 catalogoInput'
								onClick={clickSearch} onBlur={blurSearch}
								onChange={catalogoChange} onKeyDown={(e) => e.key === 'Enter' ? navigate(`/ricerca/${checkPage}/${scrittaCatalogo.trim()}`) : null}/>
					</div>
				</div>
				<div id="Raggruppa_438" className='mt-3 mt-lg-0 col-lg-4 col-12 row text-light' style={{justifyContent:'center', fontSize:16, fontFamily:'Abril Display', zIndex:10000}}>
					<div className='col-lg-12 text-end row'>
						<div className='col-0 col-lg-3' />
						<div className='col-0 col-lg-2' />
						<div className='col-6 col-lg-2 text-center px-1 px-md-0' onClick={() => routeNav("/")}>
							<FontAwesomeIcon icon={faHouse} style={{fontSize:"larger"}}/>
							<br />
							<span>HOME</span>
						</div>
						
						<div className='col-6 col-lg-3 text-center px-1 px-md-0' onClick={() => viewBurger === 'none' ? setViewBurger('block') : setViewBurger('none')}>
							<FontAwesomeIcon icon={faBars} className='text-center text-light' style={{fontSize:"larger"}}/>
							<br />
							<span>MENU</span>
						</div>
						<div className='col-0 col-lg-3' />
					</div>
				</div>
			</div>
		</div>
			
		<div id="Burger_Menu" className="Burger_Menu">
			<div id="Raggruppa_399" style={{display:viewBurger}} className='container'>
				<div id="Men_open" className='row'>
					<div className='mt-3 mt-lg-5'>
					<div id="LIBRI" className="LIBRI col-12 p-1" onClick={() => routeNav("categorie")}>
						<div id="Librifreccia">
							<div id="LIBRI_b">
								<span>LIBRI</span>
							</div>
						</div>
					</div>
					<div id="Tarocchi_e_Cart" className="Tarocchi_e_Cart_ col-12 p-1" onClick={() => routeNav("tarocchi-e-cartomanzia")}>
						<div id="TAROCCHI__E__CARTOMANZIA">
							<span>TAROCCHI E CARTOMANZIA</span>
						</div>
					</div>
					<div id="Novit" className="Novit_ col-12 p-1" onClick={() => routeNav("novita")}>
						<div id="NOVITA">
							<span>NOVITA'</span>
						</div>
					</div>
					<div id="Antiquariato" className="Antiquariato col-12 p-1" onClick={() => routeNav("antiquariato")}>
						<div id="ANTIQUARIATO">
							<span>ANTIQUARIATO</span>
						</div>
					</div>
					<div id="Notizie" className="Notizie col-12 p-1" onClick={() => routeNav("notizie")}>
						<div id="NOTIZIE__ARTICOLI__E__INFO">
							<span>NOTIZIE, ARTICOLI E INFO</span>
						</div>
					</div>
					<div id="Componente_4__1" className="Componente_4___1 col-12 p-1" onClick={() => routeNav("museo")}>
						<div id="MUSEO">
							<span>MUSEO</span>
						</div>
					</div>
					<div id="Componente_5__1" className="Componente_5___1 col-12 p-1" onClick={() => routeNav("chi-siamo")}>
						<div id="STORIA">
							<span>LA NOSTRA STORIA</span>
						</div>
					</div>
					<div className='col-12 p-2'>
						<img id="rebis_x_mosaico_definitivo" className="m-auto d-block" alt='rebis_x_mosaico_definitivo' src={rebis_x_mosaico_definitivo} />
					</div>
					</div>
						
				</div>
			</div>

		</div>
	</div>
	<Outlet />
	<Footer/>
	</>
);
}


export default Navbar;
