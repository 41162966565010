import React from "react";
import { tarocco } from "./componenti/FetchingData";
import OggettoTaroccoOCartomanzia from "./componenti/OggettoTaroccoOCartomanzia";
import carteEtarocchi from './images/carte_e_tarocchi_x_sito.png';

const TarocchiECartomanzia = ({tarocchi} : {tarocchi : tarocco[]}) => {

    return (
        <>
    
    <div id="Edizioni_Rebis_Home" style={{backgroundColor:'rgba(232,232,232,1)', backgroundSize:'100% 100%', backgroundPosition:'center center'}} className='container-fluid'>
        <div className="row justify-content-center">
            <div className="container row align-self-center p-xxl-5 cartomanziaPanel mt-5" style={{backgroundColor:'rgba(255,255,255,1)', border:'4px solid rgb(1, 65, 189)'}}>
                <div className="col-xxl-4 text-center d-flex align-items-center justify-content-center"><img src={carteEtarocchi} alt="header" className="mt-2 m-xxl-auto d-xxl-block" style={{width:'clamp(250px, 50%, 50%)'}}/></div>
                <div className="col-xxl-8 fs-3 fw-bolder" style={{fontFamily:'Abril Display', color:'rgb(1, 65, 189)'}}>
                    <span className="text-center text-md-start float-start float-xxl-none">
                        Un'importante ed unica collezione di Tarocchi e mazzi di carte per Cartomanzia rari o assolutamente inediti, 
                        frutto di accurate ricerche o tratti da preziosi originali dell'Archivio Rebis, presentati per la prima volta al mondo
                    </span>
                </div>
            </div>
            <div className='align-self-center p-3 p-lg-5' style={{backgroundColor:'rgba(232,232,232,1)'}}>
                <div id="Libri_ultime_novit" className='row justify-content-center'>
                            {
                                tarocchi.map((elem: tarocco, index) => {
                                    return <OggettoTaroccoOCartomanzia key={index} tarocco={elem} numero={index} numRow={3} />
                                })
                            }	
                </div>
            </div>
        </div>
    </div>
    </>
    );
}

export default TarocchiECartomanzia;