import React, { useEffect, useState } from "react";
import OggettoAntiquariato from "./componenti/OggettoAntiquariato";
import Matonti from './images/alessandro-matonti-ObNNop-eA-4.png'
import { antiquariato, articoloMuseo, libro, notizia, tarocco } from "./componenti/FetchingData";
import { useNavigate, useParams } from "react-router-dom";
import Libro from "./componenti/Libro";
import NotiziaSingola from "./componenti/NotiziaSingola";
import ArticoloMuseo from "./componenti/ArticoloMuseo";
import OggettoTaroccoOCartomanzia from "./componenti/OggettoTaroccoOCartomanzia";

const Ricerca = ({libri, antiquariati, tarocchi, notizie, museo} : {libri : libro[], antiquariati : antiquariato[], tarocchi : tarocco[], notizie : notizia[], museo : articoloMuseo[]}) => {

	let navigate = useNavigate(); 

    const [arrayRicerca, setArrayRicerca] = useState([]);

    const { pagina, oggetto } = useParams();


    useEffect(() => {
        const routeNav = (path : string) =>{
            navigate(path);
        }
        const oggettoSplittato = oggetto.replace("+", "†").split(" ");
        let arr = [];
        switch(pagina) {
            case ("libri"):
                arr = libri.filter((a) => {
                    let boolControllo = false;
                    for (let i = 0; i < oggettoSplittato.length; i++) {
                        if (a.nome !== "Titolo" &&
                           (a.nome.toLowerCase().includes(oggettoSplittato[i].toLowerCase()) ||
                            a.catalogo.toLowerCase().includes(oggettoSplittato[i].toLowerCase()))) {
                            boolControllo = true;
                        } else {
                            return false;
                        }
                    }
                    return boolControllo;
                })
                setArrayRicerca(arr.map((elem : libro, index) => {
                    return <Libro key={elem.nome+elem.catalogo+index} libro={elem} numero={index} numRow={3} categoria={false} blue={true} />
                }));
                break;
            case ("antiquariati"):
                arr = antiquariati.filter((a) => {
                    let boolControllo = false;
                    for (let i = 0; i < oggettoSplittato.length; i++) {
                        if (a.nome !== "Titolo" &&
                           (a.nome.toLowerCase().includes(oggettoSplittato[i].toLowerCase()))) {
                            boolControllo = true;
                        } else {
                            return false;
                        }
                    }
                    return boolControllo;
                });
                setArrayRicerca(arr.map((elem : antiquariato, index) => {
                    return <OggettoAntiquariato key={elem.nome+index} antiquariato={elem} numero={index} numRow={3} />
                }));
                break;
            case ("tarocchi"):
                arr = tarocchi.filter((a) => {
                    let boolControllo = false;
                    for (let i = 0; i < oggettoSplittato.length; i++) {
                        if (a.nome !== "Titolo" &&
                            (a.nome.toLowerCase().includes(oggettoSplittato[i].toLowerCase()) ||
                            a.catalogo.toLowerCase().includes(oggettoSplittato[i].toLowerCase()))) {
                            boolControllo = true;
                        } else {
                            return false;
                        }
                    }
                    return boolControllo;
                })
                setArrayRicerca(arr.map((elem : tarocco, index) => {
                    return <OggettoTaroccoOCartomanzia key={elem.nome+elem.catalogo+index} tarocco={elem} numero={index} numRow={3} />
                }));
                break;
            case ("notizie"):
                arr = notizie.filter((a) => {
                    let boolControllo = false;
                    for (let i = 0; i < oggettoSplittato.length; i++) {
                        if (a.titolo !== "Titolo" &&
                           (a.titolo.toLowerCase().includes(oggettoSplittato[i].toLowerCase()))) {
                            boolControllo = true;
                        } else {
                            return false;
                        }
                    }
                    return boolControllo;
                });
                setArrayRicerca(arr.map((elem : notizia, index) => {
                    return <NotiziaSingola key={elem.titolo+elem.data+index} notizia={elem} numero={index} />
                }));
                break;
            case ("museo"):
                arr = museo.filter((a) => {
                    let boolControllo = false;
                    for (let i = 0; i < oggettoSplittato.length; i++) {
                        if (a.titolo !== "Titolo" &&
                           (a.titolo.toLowerCase().includes(oggettoSplittato[i].toLowerCase()))) {
                            boolControllo = true;
                        } else {
                            return false;
                        }
                    }
                    return boolControllo;
                });
                setArrayRicerca(arr.map((elem : articoloMuseo, index) => {
                    return <ArticoloMuseo key={elem.titolo+index} articoloMuseo={elem} numero={index} />
                }));
                break;
            case ("tutto"):
                let acc = [];
                arr = libri.filter((a) => {
                    let boolControllo = false;
                    for (let i = 0; i < oggettoSplittato.length; i++) {
                        if (a.nome !== "Titolo" &&
                           (a.nome.toLowerCase().includes(oggettoSplittato[i].toLowerCase()) ||
                            a.catalogo.toLowerCase().includes(oggettoSplittato[i].toLowerCase()))) {
                            boolControllo = true;
                        } else {
                            return false;
                        }
                    }
                    return boolControllo;
                });

                if (arr.length > 0) {
                    acc.push(<div key={"LibroPage"} className="fs-1 textRobe text-center p-2 mb-2" style={{borderBottom:'1px solid rgba(1,65,189,1)'}}>LIBRI</div>);
                    acc.push(...arr.map((elem : libro, index) => {
                        return <Libro key={elem.nome+elem.catalogo+index} libro={elem} numero={index} numRow={3} categoria={false} blue={true}/>
                    }));
                }
    
                arr = antiquariati.filter((a) => {
                    let boolControllo = false;
                    for (let i = 0; i < oggettoSplittato.length; i++) {
                        if (a.nome !== "Titolo" &&
                           (a.nome.toLowerCase().includes(oggettoSplittato[i].toLowerCase()))) {
                            boolControllo = true;
                        } else {
                            return false;
                        }
                    }
                    return boolControllo;
                });
                
                if (arr.length > 0) {
                    acc.push(<div key={"AntiquariatoPage"} className="fs-1 textRobe text-center p-2 mb-2" style={{borderBottom:'1px solid rgba(1,65,189,1)'}}>ANTIQUARIATI</div>);
                    acc.push(...arr.map((elem : antiquariato, index) => {
                        return <OggettoAntiquariato key={elem.nome+elem.url+index} antiquariato={elem} numero={index} numRow={3} />
                    }));
                }

                arr = tarocchi.filter((a) => {
                    let boolControllo = false;
                    for (let i = 0; i < oggettoSplittato.length; i++) {
                        if (a.nome !== "Titolo" &&
                            (a.nome.toLowerCase().includes(oggettoSplittato[i].toLowerCase()) ||
                            a.catalogo.toLowerCase().includes(oggettoSplittato[i].toLowerCase()))) {
                            boolControllo = true;
                        } else {
                            return false;
                        }
                    }
                    return boolControllo;
                })
                if (arr.length > 0) {
                    acc.push(<div key={"TaroccoPage"} className="fs-1 textRobe text-center p-2 mb-2" style={{borderBottom:'1px solid rgba(1,65,189,1)'}}>TAROCCHI E CARTOMANZIA</div>);
                    acc.push(...arr.map((elem : tarocco, index) => {
                        return <OggettoTaroccoOCartomanzia key={elem.nome+elem.catalogo+index} tarocco={elem} numero={index} numRow={3} />
                    }));
                }
    
                arr = notizie.filter((a) => {
                    let boolControllo = false;
                    for (let i = 0; i < oggettoSplittato.length; i++) {
                        if (a.titolo !== "Titolo" &&
                           (a.titolo.toLowerCase().includes(oggettoSplittato[i].toLowerCase()))) {
                            boolControllo = true;
                        } else {
                            return false;
                        }
                    }
                    return boolControllo;
                });

                if (arr.length > 0) {
                    acc.push(<div key={"NotiziaPage"} className="fs-1 textRobe text-center p-2 mb-2" style={{borderBottom:'1px solid rgba(1,65,189,1)'}}>NOTIZIE</div>);
                    acc.push(...arr.map((elem : notizia, index) => {
                        return <NotiziaSingola key={elem.titolo+elem.data+index} notizia={elem} numero={index} />
                    }));
                }
    
                arr = museo.filter((a) => {
                    let boolControllo = false;
                    for (let i = 0; i < oggettoSplittato.length; i++) {
                        if (a.titolo !== "Titolo" &&
                           (a.titolo.toLowerCase().includes(oggettoSplittato[i].toLowerCase()))) {
                            boolControllo = true;
                        } else {
                            return false;
                        }
                    }
                    return boolControllo;
                });
                
                if (arr.length > 0) {
                    acc.push(<div key={"MuseoPage"} className="fs-1 textRobe text-center p-2 mb-2" style={{borderBottom:'1px solid rgba(1,65,189,1)'}}>MUSEO</div>);
                    acc.push(...arr.map((elem : articoloMuseo, index) => {
                        return <ArticoloMuseo key={elem.titolo+index} articoloMuseo={elem} numero={index} />
                    }));
                }
    
                setArrayRicerca(acc);
                break;
            default:
                routeNav("404");
                break;
        }
    }, [pagina, oggetto, libri, antiquariati, tarocchi, museo, notizie, navigate])


    return (
        <>
    <img src={Matonti} alt="header" className="w-100"/>
    <div id="Edizioni_Rebis_Home" style={{backgroundColor:'rgba(232,232,232,1)', backgroundSize:'100% 100%', backgroundPosition:'center center'}} className='container-fluid'>
        <div className="row justify-content-center" style={{fontFamily:'Abril Display'}}>
            <div className='align-self-center p-5 fs-1 textRobe text-center mt-2' style={{backgroundColor:'rgba(232,232,232,1)'}}>{`Hai cercato: '${oggetto.replace("+", "†")}'`}</div>
            <div className='align-self-center p-5' style={{backgroundColor:'rgba(232,232,232,1)'}}>
                <div id="Libri_ultime_novit" className='row justify-content-center'>
                            {
                                arrayRicerca.length > 0 ?
                                arrayRicerca
                                :
                                <div className="fs-1 textRobe text-center m-5">La ricerca non ha prodotto risultati</div>
                            }	
                </div>
            </div>
        </div>
    </div>
    </>
    )
}

export default Ricerca;