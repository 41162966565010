import React from "react";
import { notizia } from "./componenti/FetchingData";
import NotiziaSingola from "./componenti/NotiziaSingola";

const Notizie = ({notizie} : {notizie : notizia[]}) => {

    return (
        <div id="Edizioni_Rebis_Home" className="container-fluid pt-5 pb-5" style={{backgroundColor:'rgba(232,232,232,1)', fontWeight:'bold', fontFamily:'Abril Display'}}>
        <div className="text-center fs-1 fw-bolder text-decoration-underline mt-3" style={{ color:'rgba(221,0,0,1)'}}>Notizie, Articoli e Info</div>
            {
                notizie.map((elem, index) => {
                    return (
                        <div key={elem.titolo+index} className="row justify-content-center" style={{borderBottom:'4px solid rgba(1,65,189,1)'}}>
                            <NotiziaSingola notizia={elem} numero={index} />
                        </div>
                    )
                })
            }
        </div>
    );
}

export default Notizie;