import React, { useEffect, useState } from "react";
import { antiquariato } from "./FetchingData";
import { useNavigate } from "react-router-dom";

const OggettoAntiquariato = ({antiquariato, numero, numRow} : { antiquariato: antiquariato, numero: number | string, numRow: number } ) => {

    const navigate = useNavigate();
    const [isLink, setIsLink] = useState(false);
    useEffect(() => {
        if(antiquariato.nome !== "Titolo") {
            setIsLink(true);
        } else {
            setIsLink(false);
        }
    },[antiquariato.nome])

    function specifiche(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.preventDefault();
        if (isLink) {
            navigate(`/antiquariato/${antiquariato.nome}`);
        }
    }

    return (
        <div className={'col-xl-' + numRow*2 + ' col-xxl-' + numRow + ' p-2 align-self-stretch'}>
            <img id={"Antiquariato"+numero} src={antiquariato.url} alt={antiquariato.nome} onClick={specifiche} style={{cursor:(isLink ? 'pointer' : 'default')}} className='imgAll m-auto d-block antiquariatoCopertina libro'/>
            <div style={{margin:'auto', cursor:(isLink ? 'pointer' : 'default'), width:'clamp(185px, 200px, 75%)'}} onClick={specifiche}>
                <div id={antiquariato.nome} style={{borderBottom:'2px solid #0141BD'}} className="nomeAntiquariato fs-6">
                    <span>{antiquariato.nome}</span>
                </div>
                <div id={ antiquariato.prezzo } className="prezzoAntiquariato fs-4">
                    <span>{ `Prezzo: ${antiquariato.prezzo}€` }</span>
                </div>
            </div>
        </div>
    )
}

export default OggettoAntiquariato;