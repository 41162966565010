import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FetchAntiquariati, FetchLibri, FetchMuseo, FetchNotizie, FetchTarocchi, antiquariato, articoloMuseo, libro, notizia, tarocco } from "./ui/componenti/FetchingData";

import Navbar from './ui/Navbar';
import Home from './ui/Home';
import Novita from './ui/Novita';
import NoPage from "./ui/NoPage";
import AccediAdmin from "./ui/AccediAdmin";
import AggiungiLibri from "./ui/AggiungiLibri";
import CategorieLibri from "./ui/CategorieLibri";
import Museo from "./ui/Museo";
import Notizie from "./ui/Notizie";
import TarocchiECartomanzia from "./ui/TarocchiECartomanzia";
import ChiSiamo from "./ui/ChiSiamo";
import PrivacyPolicy from "./ui/PrivacyPolicy";
import CookiePolicy from "./ui/CookiePolicy";
import Pagina from "./ui/componenti/Pagina";
import Antiquariato from "./ui/Antiquariato";
import PaginaInformazioniLibro from "./ui/componenti/PaginaInformazioniLibro";
import PaginaInformazioniAntiquariato from "./ui/componenti/PaginaInformazioniAntiquariato";
import Aggiungi from "./ui/Aggiungi";
import AggiungiAntiquariati from "./ui/AggiungiAntiquariati";
import AggiungiTarocchi from "./ui/AggiungiTarocchi";
import AggiungiNotizie from "./ui/AggiungiNotizie";
import AggiungiMuseo from "./ui/AggiungiMuseo";
import Ricerca from "./ui/Ricerca";
import PaginaInformazioniTarocco from "./ui/componenti/PaginaInformazioniTarocco";

function App() {

	const [libri, setLibri] = useState<libro[]>([]);

  const [antiquariati, setAntiquariati] = useState<antiquariato[]>([]);

  const [tarocchi, setTarocchi] = useState<tarocco[]>([]);

  const [notizie, setNotizie] = useState<notizia[]>([]);
  
  const [articoliMuseo, setMuseo] = useState<articoloMuseo[]>([]);

  const [arrayNovita, setArrayNovita] = useState<libro[] | tarocco[]>([]);

	useEffect(() => {
    let callServer = () => {
      FetchLibri(8).then((libri : libro[]) => setLibri(libri));
      FetchAntiquariati(4).then((antiquariati : antiquariato[]) => setAntiquariati(antiquariati));
      FetchTarocchi(4).then((elemTarocchi : tarocco[]) => setTarocchi(elemTarocchi));
      FetchNotizie(1).then((notizie : notizia[]) => setNotizie(notizie));
      FetchMuseo(1).then((articoliMuseo : articoloMuseo[]) => setMuseo(articoliMuseo));
    }
    setInterval(() => {
      callServer();
    }, 5000);
    callServer();
	}, []);

  useEffect(() => {
    setArrayNovita(libri.concat(tarocchi).filter((l) => l.isnovita));
  }, [libri, tarocchi])

  return (
    <div className="App">
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Navbar />}>
          <Route index element={<Home libri={arrayNovita}/>} />
          <Route path="novita" element={<Novita libri={arrayNovita}/>} />
          <Route path="categorie" element={<CategorieLibri />} />
          <Route path="tarocchi-e-cartomanzia" element={<TarocchiECartomanzia tarocchi={tarocchi}/>} />
          <Route path="categorie/Tarocchi/:nome" element={<PaginaInformazioniTarocco tarocchi={tarocchi} tipo={"Tarocchi"}/>} />
          <Route path="categorie/Cartomanzia/:nome" element={<PaginaInformazioniTarocco tarocchi={tarocchi} tipo={"Cartomanzia"}/>} />
          <Route path="notizie" element={<Notizie notizie={notizie}/>} />
          <Route path="antiquariato" element={<Antiquariato antiquariati={antiquariati}/>} />
          <Route path="antiquariato/:nome" element={<PaginaInformazioniAntiquariato antiquariati={antiquariati}/>} />
          <Route path="museo" element={<Museo articoliMuseo={articoliMuseo}/>} />
          <Route path="ricerca/:pagina/:oggetto" element={<Ricerca libri={libri} antiquariati={antiquariati} tarocchi={tarocchi} notizie={notizie} museo={articoliMuseo} />} />
          <Route path="categorie/:categoria/:nome" element={<PaginaInformazioniLibro libri={libri}/>} />
          <Route path="categorie/:categoria" element={<Pagina />} />

          <Route path="chi-siamo" element={<ChiSiamo />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="cookie-policy" element={<CookiePolicy />} />

          <Route path="accediAdmin" element={<AccediAdmin />} />
          <Route path="aggiungi" element={<Aggiungi />} />
          <Route path="aggiungiLibri" element={<AggiungiLibri libri={libri.filter((l) => l.nome !== "Titolo")} />} />
          <Route path="aggiungiAntiquariati" element={<AggiungiAntiquariati antiquariati={antiquariati.filter((l) => l.nome !== "Titolo")} />} />
          <Route path="aggiungiTarocchiECartomanzie" element={<AggiungiTarocchi tarocchi={tarocchi.filter((l) => l.nome !== "Titolo")} />} />
          <Route path="aggiungiNotizie" element={<AggiungiNotizie notizie={notizie.filter((l) => l.titolo !== "Titolo")} />} />
          <Route path="aggiungiMuseo" element={<AggiungiMuseo museo={articoliMuseo.filter((l) => l.titolo !== "Titolo")} />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </div>
  );
}

export default App;
