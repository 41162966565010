import React from "react";
import Sfondo_libri from "./images/Sfondo_Libro.png"
import { useNavigate } from "react-router-dom";

const CategorieLibri = () => {

	let navigate = useNavigate(); 

	const routeNav = (path : string) =>{
		navigate(path);
	}

    return (
        <div id="Edizioni_Rebis_Home" style={{backgroundImage:'url("'+ Sfondo_libri +'")'}} className='container-fluid p-4'>
            <div className="row justify-content-center">
                <div id="n_ULTIME__CATEGORIE" className='col-12 p-2 p-lg-5 text-decoration-underline'>
					<span>Categorie di Libri</span>
				</div>
                <div className="col-md-4 align-self-center text-md-start text-center testoRossoCategorieHover divLink m-2 m-xxl-4" onClick={() => routeNav("abate-julio")}>Abate Julio</div>
                <div className="col-md-4 align-self-center text-md-end text-center testoRossoCategorieHover divLink m-2 m-xxl-4" onClick={() => routeNav("esoterismo")}>Esoterismo</div>
                <div className="col-md-4 align-self-center text-md-start text-center testoRossoCategorieHover divLink m-2 m-xxl-4" onClick={() => routeNav("alchimia")}>Alchimia</div>
                <div className="col-md-4 align-self-center text-md-end text-center testoRossoCategorieHover divLink m-2 m-xxl-4" onClick={() => routeNav("grimoires")}>Grimoires</div>
                <div className="col-md-4 align-self-center text-md-start text-center testoRossoCategorieHover divLink m-2 m-xxl-4" onClick={() => routeNav("angeli")}>Angeli</div>
                <div className="col-md-4 align-self-center text-md-end text-center testoRossoCategorieHover divLink m-2 m-xxl-4" onClick={() => routeNav("magia-pratica")}>Magia Pratica</div>
                <div className="col-md-4 align-self-center text-md-start text-center testoRossoCategorieHover divLink m-2 m-xxl-4" onClick={() => routeNav("arti-divinatorie")}>Arti Divinatorie</div>
                <div className="col-md-4 align-self-center text-md-end text-center testoRossoCategorieHover divLink m-2 m-xxl-4" onClick={() => routeNav("magia-rituale")}>Magia Rituale</div>
                <div className="col-md-4 align-self-center text-md-start text-center testoRossoCategorieHover divLink m-2 m-xxl-4" onClick={() => routeNav("cabala")}>Cabala</div>
                <div className="col-md-4 align-self-center text-md-end text-center testoRossoCategorieHover divLink m-2 m-xxl-4" onClick={() => routeNav("opere-pierini")}>Opere di Pier Luca Pierini R.</div>
                <div className="col-md-4 align-self-center text-md-start text-center testoRossoCategorieHover divLink m-2 m-xxl-4" onClick={() => routeNav("colonna-i-gioielli")}>Colonna I Gioielli</div>
                <div className="col-md-4 align-self-center text-md-end text-center testoRossoCategorieHover divLink m-2 m-xxl-4" onClick={() => routeNav("rivista-elixir")}>Rivista Elixir</div>
                <div className="col-md-4 align-self-center text-md-start text-center testoRossoCategorieHover divLink m-2 m-xxl-4" onClick={() => routeNav("ermetismo")}>Ermetismo</div>
                <div className="col-md-4 align-self-center text-md-end text-center testoRossoCategorieHover divLink m-2 m-xxl-4" onClick={() => routeNav("rosa-croce")}>Rosa † Croce</div>
            
            </div>
        </div>
    );
}

export default CategorieLibri;