import React, { useEffect, useState } from 'react';
import './Home.css';
import Sfondo_libri from './images/Sfondo_libri.png';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import checkLogin from './componenti/loginChecker';
import { useNavigate } from 'react-router-dom';

//PAGINA PER L'ADMIN
const AccediAdmin = () => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(true);
    const [errorUsernameSub, setErrorUsernameSub] = useState(false);
    const [errorPasswordSub, setErrorPasswordSub] = useState(false);
    const [errorSub, setErrorSub] = useState(false);
    const [errorUsernameMessage, setErrorUsernameMessage] = useState("");
    const [errorPasswordMessage, setErrorPasswordMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        checkLogin().then((res) => res ? navigate("/aggiungi") : null)
    },[navigate])
    

    const login = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrorSub(false);
        if (username.length === 0) {
            setErrorUsernameSub(true);
            setErrorUsernameMessage("Devi inserire l'username!")
            return;
        }
        if (password.length === 0) {
            setErrorPasswordSub(true);
            setErrorPasswordMessage("Devi inserire la password!")
            return;
        }
        setErrorUsernameSub(false);
        setErrorPasswordSub(false);

        const postObject = { username: username, password: password };

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(postObject)
        };

        await fetch('https://edizionirebis.com:57447/login', requestOptions)
            .then(response => response.json())
            .then((data : string) => {
                if (data.startsWith("Username")) {
                    setErrorUsernameSub(true);
                    setErrorUsernameMessage(data);
                    return;
                }
                else if(data.startsWith("Password")) {
                    setErrorPasswordSub(true);
                    setErrorPasswordMessage(data)
                    return;
                }

                if (rememberMe)
                    localStorage.setItem('Authorization', data);
                else
                    sessionStorage.setItem('Authorization', data);

                navigate("/aggiungi")
            })
            .catch((err) => {setErrorSub(true); setErrorMessage(err.toString())});
    }

    return (
	<div id="Edizioni_Rebis_Home" style={{backgroundImage:'url("'+ Sfondo_libri +'")'}} className='container-fluid p-5'>
		<div className="row justify-content-center">
			<div className='col-lg-4 align-self-center p-5' style={{backgroundColor:'rgba(255,255,255,0.3)', borderRadius:'25px', backdropFilter:'blur(50px)'}}>
                <h1 className='display-3'>
                    Accedi
                </h1>
                <h5 className='text-danger' style={{display: (errorSub ? 'initial' : 'none'), marginBottom:10}}>
                    {errorMessage}
                </h5>
                <Form onSubmit={(e) => login(e)}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Username</Form.Label>
                        <Form.Control isInvalid={errorUsernameSub} type="username" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                        <Form.Control.Feedback type="invalid">
                            {errorUsernameMessage}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control isInvalid={errorPasswordSub} type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        <Form.Control.Feedback type="invalid">
                            {errorPasswordMessage}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Ricordami" defaultChecked={rememberMe} onChange={() => setRememberMe(!rememberMe)}/>
                    </Form.Group>
                    <Button variant="outline-primary" className="btnFormSend w-100" type="submit">
                        Accedi
                    </Button>
                </Form>
			</div>
		</div>
	</div>
    )
}

export default AccediAdmin;