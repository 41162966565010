import React, { useState, useEffect } from 'react';
import './Home.css';
import Sfondo_libri from './images/immagine_per_sito1-PhotoRoompn.png';
import SfingeSingola from './images/SfingeSingolaOpaca.png';
import Libro from './componenti/Libro';
import { libro } from './componenti/FetchingData';
import useWindowDimensions from './componenti/windowDimensions';


//PAGINA NOVITA
const Novita = ({libri} : {libri : libro[]}) => {

	const [nuoviLibri, setNuoviLibri] : [libro[], any] = useState([])
	const [altriLibri, setAltriLibri] : [libro[], any] = useState([])
	const { width } = useWindowDimensions();

	useEffect(() => {
		setNuoviLibri(libri.slice(0,4));
		setAltriLibri(libri.slice(4,12));
	}, [libri]);

    return (
		<>
		<div id="Edizioni_Rebis_Home" style={{backgroundImage:'url("'+ (width > 1700 ? Sfondo_libri : SfingeSingola) +'")', backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundColor:'rgba(232,232,232,1)', backgroundPosition:'center center'}} className='container-fluid p-5'>
		<div className="row justify-content-center">
			<div className="text-center fs-1 fw-bolder text-decoration-underline mt-3" style={{fontFamily:'Abril Display', color:'rgba(221,0,0,1)'}}>Novità</div>
			<div className='col-lg-4 align-self-center p-3 p-lg-5'>
				<div id="Ultime_Novit" className='row justify-content-center'>
					<div id="Libri_ultime_novit" className='row m-lg-auto justify-content-center'>
					{
						nuoviLibri.map((elem: libro, index) => {
							return <Libro key={index} libro={elem} numero={index} numRow={6} categoria={false} blue={true}/>
						})
					}	
					</div>

				</div>
			</div>
		</div>
	</div>

	<div className='col-lg-12 align-self-center p-5' style={{backgroundColor:'rgba(232,232,232,1)'}}>
		<div id="Libri_ultime_novit" className='row m-lg-auto justify-content-center'>
					{
						altriLibri.map((elem: libro, index) => {
							return <Libro key={index} libro={elem} numero={index} numRow={3} categoria={false} blue={true}/>
						})
					}	
		</div>
	</div>
	</>
    )
}

export default Novita;