import React from 'react';
import './Home.css';
import Sfondo_libri from './images/Sfondo_libri.png';
import Foto_Edizioni_Rebis from './images/Foto_Edizioni_Rebis.png';
import Libro from './componenti/Libro';
import { libro } from './componenti/FetchingData';


//PAGINA HOMEPAGE
const Home = ({libri} : {libri : libro[]}) => {

    return (
	<div id="Edizioni_Rebis_Home" style={{backgroundImage:'url("'+ Sfondo_libri +'")'}} className='container-fluid p-5'>
		<div className="row align-items-center">
			<div className='col-lg-6'>
				<img id="Foto_Edizioni_Rebis" alt='Foto_Edizioni_Rebis' src={Foto_Edizioni_Rebis} className='m-auto d-block img-fluid'/>
				<div id="Centro_Editoriale_Rebis" className='m-auto d-block'>
					<span>Centro Editoriale Rebis</span>
				</div>
				<div id="Dal_1970_Libri_Rari_di_Scienze" className='m-auto d-block'>
					<span>Dal 1970 Libri Rari di Scienze Occulte</span>
				</div>
			</div>
		
			<div key={"ULTIMENNovità"} className='col-lg-6'>
				<div id="Ultime_Novit" className='row'>
					<div id="n_ULTIME__NOVITA" className='col-12 p-2 my-3 my-lg-0 text-decoration-underline'>
						<span id="SpanUltimeNovita">ULTIME NOVITA'</span>
					</div>
					<div id="Libri_ultime_novit" className='row m-auto'>
					{
						libri.map((elem: libro, index) => {
							if (index < 6)
								return <Libro key={elem.nome+elem.catalogo+index} libro={elem} numero={index} numRow={4} categoria={false} blue={false}/>
							else return <></>
						})
					}	
					</div>

				</div>
			</div>
		</div>
	</div>
    )
}

export default Home;