const checkLogin = async () => {
    let isLogged = false;
    const auth = localStorage.getItem('Authorization') || sessionStorage.getItem('Authorization');
    if (!auth) return isLogged;
    const requestOptions : RequestInit = {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth}` },
        method: 'POST',
        body: JSON.stringify({ 'auth': auth })
    };

    await fetch('https://edizionirebis.com:57447/login', requestOptions)
        .then(response => response.json())
        .then(data => {
            if (data === "OK") isLogged = true;
            else isLogged = false;
        })
        .catch(() => isLogged = false)

        if (!isLogged) {
            localStorage.removeItem('Authorization');
            sessionStorage.removeItem('Authorization');
        } 
    
    return isLogged;
}

export default checkLogin;