import LibroUnk from '../images/LibroUnk.jpg'
export type libro = { nome: string, catalogo: string, url: string, prezzo?: string, descrizione?: string, linkSito?: string, isnovita?: string }
export type tarocco = { nome: string, catalogo: string, url: string, prezzo?: string, descrizione?: string, linkSito?: string, isnovita?: string }
export type antiquariato = { nome: string, url: string, prezzo?: string, descrizione?: string, linkSito?: string }
export type notizia = { titolo: string, url: string, data: string, descrizione?: string }
export type articoloMuseo = { titolo: string, url: string, descrizione?: string }

export const FetchLibri = async (max : number, filter?: string) => {
    let arr = [];
    let siteURL = 'https://edizionirebis.com:57447/libri';
    if (filter) siteURL += filter;

    await fetch(siteURL)
        .then(response => response.json())
        .then(data => {
            arr = data
        })
        .catch(() => null);
    
    
    while (arr.length < max) {
        arr.push({ url: LibroUnk, nome: "Titolo", catalogo: "Catalogo", prezzo: "0.00"})
    }

    return arr;
}

export const FetchAntiquariati = async (max : number, filter?: string) => {
    let arr = [];
    let siteURL = 'https://edizionirebis.com:57447/antiquariati';
    if (filter) siteURL += filter;

    await fetch(siteURL)
        .then(response => response.json())
        .then(data => {
            arr = data
        })
        .catch(() => null);
    
    
    while (arr.length < max) {
        arr.push({ url: LibroUnk, nome: "Titolo", prezzo: "0.00"})
    }

    return arr;
}

export const FetchTarocchi = async (max : number, filter?: string) => {
    let arr = [];
    let siteURL = 'https://edizionirebis.com:57447/tarocchi';
    if (filter) siteURL += filter;

    await fetch(siteURL)
        .then(response => response.json())
        .then(data => {
            arr = data
        })
        .catch(() => null);
    
    
    while (arr.length < max) {
        arr.push({ url: LibroUnk, nome: "Titolo", catalogo: "Catalogo",prezzo: "0.00"})
    }

    return arr;
}

export const FetchNotizie = async (max : number) => {
    let arr = [];
    let siteURL = 'https://edizionirebis.com:57447/notizie';

    await fetch(siteURL)
        .then(response => response.json())
        .then(data => {
            arr = data
        })
        .catch(() => null);
    
    const data = new Date().toISOString().slice(0, 19).replace('T', ' ');
    
    while (arr.length < max) {
        arr.push({ url: LibroUnk, titolo: "Titolo", data: data, descrizione: "Articolo vuoto..."})
    }

    return arr;
}

export const FetchMuseo = async (max : number) => {
    let arr = [];
    let siteURL = 'https://edizionirebis.com:57447/museo';

    await fetch(siteURL)
        .then(response => response.json())
        .then(data => {
            arr = data
        })
        .catch(() => null);
    
    while (arr.length < max) {
        arr.push({ url: LibroUnk, titolo: "Titolo", descrizione: "Articolo vuoto..."})
    }

    return arr;
}