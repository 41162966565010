import React, { useState, useEffect } from "react";
import Libro from "./Libro";
import clientLoader from '../images/client-loader.gif'
import Matonti from '../images/alessandro-matonti-ObNNop-eA-4.png'
import { libro, FetchLibri } from "./FetchingData";
import { useNavigate, useParams } from "react-router-dom";

const Pagina = () => {

    const [libri, setLibri] = useState([{ url: clientLoader, nome: "Da inserire", catalogo: "Da inserire"}, { url: clientLoader, nome: "Da inserire", catalogo: "Da inserire"}, 
    { url: clientLoader, nome: "Da inserire", catalogo: "Da inserire"}, { url: clientLoader, nome: "Da inserire", catalogo: "Da inserire"}]);
    let nomeCategoria = "";
    let nomeParse = "?catalogo=";
    
    const navigate = useNavigate();
    const { categoria } = useParams();

    switch(categoria) {
        case "abate-julio":
            nomeParse += "Abate Julio";
            nomeCategoria = "Abate Julio";
            break;
        case "alchimia":
            nomeParse += "Alchimia";
            nomeCategoria = "Alchimia";
            break;
        case "angeli":
            nomeParse += "Angeli";
            nomeCategoria = "Angeli";
            break;
        case "arti-divinatorie":
            nomeParse += "Arti Divinatorie";
            nomeCategoria = "Arti Divinatorie";
            break;
        case "cabala":
            nomeParse += "Cabala";
            nomeCategoria = "Cabala";
            break;
        case "rivista-elixir":
            nomeParse += "Rivista Elixir";
            nomeCategoria = "Rivista Elixir";
            break;
        case "ermetismo":
            nomeParse += "Ermetismo";
            nomeCategoria = "Ermetismo";
            break;
        case "esoterismo":
            nomeParse += "Esoterismo";
            nomeCategoria = "Esoterismo";
            break;
        case "colonna-i-gioielli":
            nomeParse += "Colonna I Gioielli";
            nomeCategoria = "Colonna I Gioielli";
            break;
        case "grimoires":
            nomeParse += "Grimoires";
            nomeCategoria = "Grimoires";
            break;
        case "magia-pratica":
            nomeParse += "Magia Pratica";
            nomeCategoria = "Magia Pratica";
            break;
        case "magia-rituale":
            nomeParse += "Magia Rituale";
            nomeCategoria = "Magia Rituale";
            break;
        case "opere-pierini":
            nomeParse += "Opere Pierini";
            nomeCategoria = "Opere di Pier Luca Pierini R.";
            break;
        case "rosa-croce":
            nomeParse += "Rosa † Croce";
            nomeCategoria = "Rosa † Croce";
            break;
        default:
            navigate("/404")
            break;
    }

    useEffect(() => {
		FetchLibri(4, nomeParse).then((libri : libro[]) => setLibri(libri));
    }, [nomeParse])

    return (
        <>
    <img src={Matonti} alt="header" className="w-100"/>
    <div id="Edizioni_Rebis_Home" style={{backgroundColor:'rgba(232,232,232,1)', backgroundSize:'100% 100%', backgroundPosition:'center center'}} className='container-fluid'>
        <div className="row justify-content-center">
            <div className="text-center fs-1 fw-bolder text-decoration-underline mt-3" style={{fontFamily:'Abril Display', color:'rgba(221,0,0,1)'}}>{nomeCategoria}</div>
            <div className='col-lg-12 align-self-center p-3 p-lg-5' style={{backgroundColor:'rgba(232,232,232,1)'}}>
                <div id="Libri_ultime_novit" className='row justify-content-center'>
                            {
                                libri.map((elem: libro, index) => {
                                    return <Libro key={index} libro={elem} numero={index} numRow={3} categoria={true} blue={true}/>
                                })
                            }	
                </div>
            </div>
        </div>
    </div>
    </>
    )
}

export default Pagina;