import React, { useEffect, useState } from "react";
import { notizia } from "./FetchingData";
import useWindowDimensions from "./windowDimensions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleDown, faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";

const NotiziaSingola = ({notizia, numero} : { notizia: notizia, numero: number | string } ) => {

	const { width } = useWindowDimensions();
    const [newData, setNewData] = useState("");
    const [newDescrizione, setNewDescrizione] = useState("");
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        setNewData(new Date(notizia.data).toLocaleDateString("it-IT"));
        setNewDescrizione(notizia.descrizione && notizia.descrizione.length > 0 ? notizia.descrizione : "L'articolo è vuoto...")
    }, [notizia.data, notizia.descrizione])
    
    return (
        <div className="row justify-content-center fs-4 fs-md-3 p-2 p-lg-0" style={{color:'rgba(1,65,189,1)'}}>
        <div className={"container p-lg-5 " + (width > 993 ? 'w-75' : 'w-100')}>
            <div style={{borderBottom:'1px solid rgba(1,65,189,1)'}}>
                <span>{newData}</span>
            </div>
            <div className="mt-2 mb-2">
                <span>{notizia.titolo}</span>
            </div>
                {
                width <= 993 ?
                <div className="row justify-content-center">
                    <div className="col-lg-4 mb-4 mb-lg-0">
                        <img id={"Notizia"+numero} src={notizia.url} alt={notizia.titolo+notizia.data} width="165px" height="200px" className='m-auto d-block m-lg-0 antiquariatoCopertina libro'/>
                    </div>
                    <div className="col-lg-8 p-1 p-lg-4 m-auto m-lg-0" style={{backgroundColor:'rgba(255,255,255,1)', border:'4px solid rgba(1,65,189,1)'}}>
                        {
                            newDescrizione.length > 30 ?
                            <>
                                <p>
                                    {showAll ? newDescrizione : newDescrizione.split(" ", 30).join(" ")}
                                </p>
                                <p className="mx-2 p-1 px-4 float-end" 
                                    style={{border:'2px solid rgba(1,65,189,1)', borderRadius:'15px', cursor:'pointer'}}
                                    onClick={() => setShowAll(!showAll)}
                                >
                                    {   
                                        showAll ?
                                        <>LEGGI MENO{'  '}<FontAwesomeIcon icon={faArrowCircleUp} /></>
                                        :
                                        <>LEGGI TUTTO{'  '}<FontAwesomeIcon icon={faArrowCircleDown} /></>
                                    }
                                </p>
                            </>
                            :
                            <p>
                                {newDescrizione}
                            </p>
                        }
                    </div>
                </div>
                :
                (numero as number)%2 === 0 ?
                    <div className="row justify-content-center">
                        <div className="col-lg-4 mb-4 mb-lg-0">
                        <img id={"Notizia"+numero} src={notizia.url} alt={notizia.titolo+notizia.data} width="165px" height="200px" className='m-auto d-block m-lg-0 antiquariatoCopertina libro'/>
                        </div>
                        <div className="col-lg-8 p-1 p-lg-4 m-auto m-lg-0" style={{backgroundColor:'rgba(255,255,255,1)', border:'4px solid rgba(1,65,189,1)'}}>
                        {
                            newDescrizione.length > 30 ?
                            <>
                                <p>
                                    {showAll ? newDescrizione : newDescrizione.split(" ", 30).join(" ")}
                                </p>
                                <p className="mx-2 p-1 px-4 float-end" 
                                    style={{border:'2px solid rgba(1,65,189,1)', borderRadius:'15px', cursor:'pointer'}}
                                    onClick={() => setShowAll(!showAll)}
                                >
                                    {   
                                        showAll ?
                                        <>LEGGI MENO{'  '}<FontAwesomeIcon icon={faArrowCircleUp} /></>
                                        :
                                        <>LEGGI TUTTO{'  '}<FontAwesomeIcon icon={faArrowCircleDown} /></>
                                    }
                                </p>
                            </>
                            :
                            <p>
                                {newDescrizione}
                            </p>
                        }
                        </div>
                    </div>
                    :
                    <div className="row justify-content-center">
                        <div className="col-lg-8 p-1 p-lg-4 m-auto m-lg-0" style={{backgroundColor:'rgba(255,255,255,1)', border:'4px solid rgba(1,65,189,1)'}}>
                        {
                            newDescrizione.length > 30 ?
                            <>
                                <p>
                                    {showAll ? newDescrizione : newDescrizione.split(" ", 30).join(" ")}
                                </p>
                                <p className="mx-2 p-1 px-4 float-end" 
                                    style={{border:'2px solid rgba(1,65,189,1)', borderRadius:'15px', cursor:'pointer'}}
                                    onClick={() => setShowAll(!showAll)}
                                >
                                    {   
                                        showAll ?
                                        <>LEGGI MENO{'  '}<FontAwesomeIcon icon={faArrowCircleUp} /></>
                                        :
                                        <>LEGGI TUTTO{'  '}<FontAwesomeIcon icon={faArrowCircleDown} /></>
                                    }
                                </p>
                            </>
                            :
                            <p>
                                {newDescrizione}
                            </p>
                        }
                        </div>
                        <div className="col-lg-4 mb-4 mb-lg-0 text-end">
                        <img id={"Notizia"+numero} src={notizia.url} alt={notizia.titolo+notizia.data} width="165px" height="200px" className='m-lg-0 antiquariatoCopertina libro'/>
                        </div>
                    </div>
                }
        </div>
    </div>
    )
}

export default NotiziaSingola;