import React, { useState, useEffect } from "react";
import clientLoader from '../images/client-loader.gif'
import Matonti from '../images/alessandro-matonti-ObNNop-eA-4.png'
import { antiquariato } from "./FetchingData";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBagShopping, faEuro } from "@fortawesome/free-solid-svg-icons";

const PaginaInformazioniAntiquariato = ({antiquariati} : {antiquariati : antiquariato[]}) => {

    const [antiquariato, setAntiquariato] = useState<antiquariato>({url: clientLoader, nome: "Titolo"});
    const [isHover, setIsHover] = useState<boolean>(false);

    const { nome } = useParams();

    useEffect(() => {
		setAntiquariato(antiquariati.find((antiquariato) => antiquariato.nome === nome))
	}, [nome, antiquariati]);

    function hoverAcquista(bool : boolean) {
        setIsHover(bool);
    }

    return (
        <>
    <img src={Matonti} alt="header" className="w-100"/>
    <div id="Edizioni_Rebis_Home" style={{backgroundColor:'rgba(232,232,232,1)', backgroundSize:'100% 100%', backgroundPosition:'center center', fontFamily:'Abril Display'}} className='container-fluid p-lg-5 p-3'>
        <div className="row justify-content-center">
            <div className='col-lg-8 col-xxl-6 align-items-center intestazione'>
                <img id={"Antiquariato"+antiquariato?.nome} src={antiquariato?.url} alt={antiquariato?.nome} width="245px" height="307px" className='antiquariatoCopertina libro m-xxl-auto img-fluid'/>
                <div className="p-3">
                    <h1 className="text-center fw-bold bottoneAcquistaPagina" style={{borderBottom:'1px solid rgba(221,0,0,1)', color:'rgba(221,0,0,1)'}}>{antiquariato?.nome}</h1>
                    <h2 className="text-center fw-bold bottoneAcquistaPagina" style={{color:'rgba(221,0,0,1)'}}>{antiquariato?.prezzo}<FontAwesomeIcon icon={faEuro} className="ms-2 ms-md-4" /></h2>
                    <h1 className="text-center pt-1 fw-bold bottoneAcquistaPagina" onMouseOver={() => hoverAcquista(true)} onMouseOut={() => hoverAcquista(false)}
                        style={{border:(isHover ? '2px solid rgba(221,0,0,1)' : '2px solid rgba(1,65,189,1)'), color:(isHover ? 'rgba(221,0,0,1)' :  'rgba(1,65,189,1)'), borderRadius:'20px', cursor:(antiquariato?.linkSito ? 'pointer' : 'not-allowed')}}
                        onClick={() => antiquariato?.linkSito ? window.open(antiquariato?.linkSito, "_blank") : null}
                    >ACQUISTA<FontAwesomeIcon icon={faBagShopping} className="ms-2 ms-md-4" /></h1>
                </div>
            </div>
            <div className='col-12 align-items-center p-4 mt-lg-5'>
                <h1 className="text-center fw-bold" style={{color:'rgba(221,0,0,1)'}}>
                    <u>{antiquariato?.nome}</u>
                </h1>
            </div>
            <div className='col-12 col-lg-10 align-items-center pb-4 m-lg-5'>
                <p className="text-center fw-bold textRobeInformazioni" dangerouslySetInnerHTML={{__html: (antiquariato?.descrizione ? antiquariato?.descrizione.replaceAll("\n", "</p><p>") : "")}} />
            </div>
        </div>
    </div>
    </>
    )
}

export default PaginaInformazioniAntiquariato;