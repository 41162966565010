import React, { useEffect, useState } from "react";
import { libro } from "./FetchingData";
import { useNavigate } from "react-router-dom";

const Libro = ({libro, numero, numRow, categoria, blue} : { libro: libro, numero: number | string, numRow: number, categoria: boolean, blue: boolean } ) => {
    const navigate = useNavigate();
    const [isLink, setIsLink] = useState(false);
    useEffect(() => {
        if(libro.nome !== "Titolo" || libro.catalogo !== "Catalogo") {
            setIsLink(true);
        } else {
            setIsLink(false);
        }
    },[libro.nome, libro.catalogo])

    function specifiche(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.preventDefault();
        if (isLink) {
            navigate(`/categorie/${libro.catalogo}/${libro.nome}`);
        }
    }

    return (
        <div className={'col-md-' + (numRow+2) + ' col-xxl-' + numRow + ' p-2 align-self-stretch'}>
            <img id={"Libro"+numero} src={libro.url} alt={libro.nome}  onClick={specifiche} style={{cursor:(isLink ? 'pointer' : 'default')}} className={'imgAll m-auto d-block ' + (blue ? 'antiquariatoCopertina' : 'libroCopertina') + ' libro'}/>
            <div style={{margin:'auto', cursor:(isLink ? 'pointer' : 'default'), width:'clamp(185px, 200px, 75%)'}} onClick={specifiche}>
                <div id={libro.nome} style={{borderBottom:(blue ? '2px solid rgb(1, 65, 189)' : '2px solid rgba(221,0,0,1)')}} className={(blue ? "nomeAntiquariato" : "nomeLibro") + " fs-6"}>
                    <span>{libro.nome}</span>
                </div>
                <div id={ categoria ? libro.prezzo : libro.catalogo } className={(blue ? "prezzoAntiquariato" : "catalogoLibro") + " fs-4"}>
                    <span>{ categoria ? `Prezzo: ${libro.prezzo}€` : (libro.catalogo === "Opere Pierini" ? "Opere di Pier Luca Pierini R." : libro.catalogo) }</span>
                </div>
            </div>
        </div>
    )
}

export default Libro;