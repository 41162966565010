import React, { useEffect, useState } from "react";
import checkLogin from "./componenti/loginChecker";
import { useNavigate } from 'react-router-dom';
import Sfondo_libri from './images/Sfondo_libri.png';
import { tarocco } from "./componenti/FetchingData";
import PannelloChangeTarocco from "./componenti/PannelloChangeTarocco";
import FormAggiungiTarocco from "./componenti/FormAggiungiTarocco";
import FiltroCerca from "./componenti/FiltroCerca";

const Pagination = ({totalPosts, postsPerPage, setCurrentPage, currentPage}) => {
    let pages = [];

    for (let i = 1; i <= Math.ceil(totalPosts/postsPerPage); i++) {
        pages.push(i);
    } 

    return (
        <div className="mt-2 align-self-center" style={{paddingBottom:"10px", textAlignLast:'center'}}>
            {
                pages.map((page, index) => {
                    return <button key={index} 
                    onClick={() => setCurrentPage(page)}
                    className={"btn btn-outline-primary " + (page === currentPage ? 'active' : '')}
                    style={{padding:"10px", marginRight:"5px"}}
                    >{page}</button>
                })
            }
        </div>
    )
}

const AggiungiTarocchi = ({tarocchi} : {tarocchi : tarocco[]}) => {

    const navigate = useNavigate();

    const [array, setArray] = useState<tarocco[]>([]);
    const [filtro, setFiltro] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(6);

    const [errorSub, setErrorSub] = useState(false);
    const [successSub, setSuccessSub] = useState(false);
    const [errorModificaSub, setErrorModificaSub] = useState(false);
    const [successModificaSub, setSuccessModificaSub] = useState(false);

    useEffect(() => {
        checkLogin().then((res) => !res ? navigate("/accediAdmin") : null)
    }, [navigate])


    useEffect(() => {
            tarocchi.sort((a,b) => (a.catalogo > b.catalogo ? 1 : -1));
            if (filtro.filter((p : string) => p.slice().trim().length > 0).length > 0) setArray(tarocchi.filter((tarocco) => {
                let bool = false;
                for (let i = 0; i < filtro.length; i++) {
                    const parola = filtro[i];
                    if(parola.slice().trim().length === 0) continue;
                    if (tarocco.nome.toLowerCase().includes(parola.toLowerCase()) || 
                        tarocco.catalogo.toLowerCase().includes(parola.toLowerCase())) {
                        bool = true;
                    } else {
                        bool = false;
                    }
                }
                return bool;
            }))
            else setArray(tarocchi);
    }, [tarocchi, filtro, successSub, successModificaSub])
    
    const lastPostIndex = currentPage * postsPerPage;
    const firstPostIndex = lastPostIndex - postsPerPage;
    const currentPosts = array.slice(firstPostIndex, lastPostIndex);

    return (
        <div id="Edizioni_Rebis_Home" style={{backgroundImage:'url("'+ Sfondo_libri +'")'}} className='container-fluid p-5'>
		<div className="row justify-content-center">
			<div className='col-lg-8 align-self-center p-5 row justify-content-center' style={{backgroundColor:'rgba(255,255,255,0.3)', borderRadius:'25px', backdropFilter:'blur(50px)'}}>
                <div>
                    <h1 className='display-3'>
                        Aggiungi un Tarocco o una Cartomanzia
                    </h1>
                    <h5 className='text-danger' style={{display: (errorSub ? 'initial' : 'none'), marginBottom:10}}>
                        Errore durante l'inserimento...
                    </h5>
                    <h5 className='text-success' style={{display: (successSub ? 'initial' : 'none'), marginBottom:10}}>
                        Inserimento completato!
                    </h5>
                    <FormAggiungiTarocco setErrorSub={setErrorSub} setSuccessSub={setSuccessSub} tarocchi={tarocchi}/>
                </div>

                <div>
                    <h1 className='display-3 mt-5'>
                            Modifica i tarocchi o cartomanzie
                        </h1>
                        <h5 className='text-danger' style={{display: (errorModificaSub ? 'initial' : 'none'), marginBottom:10}}>
                            Errore durante la modifica...
                        </h5>
                        <h5 className='text-success' style={{display: (successModificaSub ? 'initial' : 'none'), marginBottom:10}}>
                            Operazione completata!
                        </h5>
                    <FiltroCerca filtro={filtro} setFiltro={setFiltro}/>
                    <div className="row justify-content-center">
                            {array.length > 0 ? currentPosts.map((tarocco: tarocco) => {
                                return (
                                <div key={tarocco.nome+tarocco.catalogo} className="col-xxl-3 col-xl-4 col-md-6 align-self-center p-4">
                                    <PannelloChangeTarocco tarocco={tarocco} setErrorSub={setErrorModificaSub} setSuccessSub={setSuccessModificaSub}/>
                                </div>
                                )
                            })
                            :
                            <div className="p-1 m-1 testoRosso">
                                Nessun tarocco o cartomanzia trovato per il momento...
                            </div>
                            }
                        <Pagination totalPosts={array.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage}/>
                    </div>
                </div>
			</div>
		</div>
	</div>
    );
}

export default AggiungiTarocchi;