import React, { useEffect, useState } from "react";
import checkLogin from "./componenti/loginChecker";
import { useNavigate } from 'react-router-dom';
import Sfondo_libri from './images/Sfondo_libri.png';
import { articoloMuseo } from "./componenti/FetchingData";
import PannelloChangeMuseo from "./componenti/PannelloChangeMuseo";
import FormAggiungiMuseo from "./componenti/FormAggiungiMuseo";
import FiltroCerca from "./componenti/FiltroCerca";

const Pagination = ({totalPosts, postsPerPage, setCurrentPage, currentPage} : { totalPosts: number, postsPerPage: number, setCurrentPage: React.Dispatch<React.SetStateAction<number>>, currentPage: number}) => {
    let pages : number[] = [];

    for (let i = 1; i <= Math.ceil(totalPosts/postsPerPage); i++) {
        pages.push(i);
    } 

    return (
        <div className="mt-2 align-self-center" style={{paddingBottom:"10px", textAlignLast:'center'}}>
            {
                pages.map((page, index) => {
                    return <button key={index} 
                    onClick={() => setCurrentPage(page)}
                    className={"btn btn-outline-primary " + (page === currentPage ? 'active' : '')}
                    style={{padding:"10px", marginRight:"5px"}}
                    >{page}</button>
                })
            }
        </div>
    )
}

const AggiungiMuseo = ({museo} : {museo : articoloMuseo[]}) => {

    const navigate = useNavigate();

    const [array, setArray] = useState<articoloMuseo[]>([]);
    const [filtro, setFiltro] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(6);

    const [errorSub, setErrorSub] = useState(false);
    const [successSub, setSuccessSub] = useState(false);
    const [errorModificaSub, setErrorModificaSub] = useState(false);
    const [successModificaSub, setSuccessModificaSub] = useState(false);

    useEffect(() => {
        checkLogin().then((res) => !res ? navigate("/accediAdmin") : null)
    }, [navigate])

    useEffect(() => {
            if (filtro.filter((p : string) => p.slice().trim().length > 0).length > 0) setArray(museo.filter((articoloMuseo) => {
                let bool = false;
                for (let i = 0; i < filtro.length; i++) {
                    const parola = filtro[i];
                    if(parola.slice().trim().length === 0) continue;
                    if (articoloMuseo.titolo.toLowerCase().includes(parola.toLowerCase())) {
                        bool = true;
                    } else {
                        bool = false;
                    }
                }
                return bool;
            }));
            else setArray(museo);
    }, [museo, filtro, successSub, successModificaSub])
    
    const lastPostIndex = currentPage * postsPerPage;
    const firstPostIndex = lastPostIndex - postsPerPage;
    const currentPosts = array.slice(firstPostIndex, lastPostIndex);

    return (
        <div id="Edizioni_Rebis_Home" style={{backgroundImage:'url("'+ Sfondo_libri +'")'}} className='container-fluid p-5'>
		<div className="row justify-content-center">
			<div className='col-lg-8 align-self-center p-5 row justify-content-center' style={{backgroundColor:'rgba(255,255,255,0.3)', borderRadius:'25px', backdropFilter:'blur(50px)'}}>
                <div>
                    <h1 className='display-3'>
                        Aggiungi un articolo
                    </h1>
                    <h5 className='text-danger' style={{display: (errorSub ? 'initial' : 'none'), marginBottom:10}}>
                        Errore durante l'inserimento...
                    </h5>
                    <h5 className='text-success' style={{display: (successSub ? 'initial' : 'none'), marginBottom:10}}>
                        Inserimento completato!
                    </h5>
                    <FormAggiungiMuseo setErrorSub={setErrorSub} setSuccessSub={setSuccessSub}/>
                </div>

                <div>
                    <h1 className='display-3 mt-5'>
                            Modifica gli articoli
                        </h1>
                        <h5 className='text-danger' style={{display: (errorModificaSub ? 'initial' : 'none'), marginBottom:10}}>
                            Errore durante la modifica...
                        </h5>
                        <h5 className='text-success' style={{display: (successModificaSub ? 'initial' : 'none'), marginBottom:10}}>
                            Operazione completata!
                        </h5>
                    <FiltroCerca setFiltro={setFiltro} filtro={filtro}/>
                    <div className="row justify-content-center">
                            {array.length > 0 ? currentPosts.map((articoloMuseo: articoloMuseo) => {
                                return (
                                <div key={articoloMuseo.titolo} className="col-xxl-3 col-xl-4 col-md-6 align-self-center p-4">
                                    <PannelloChangeMuseo articoloMuseo={articoloMuseo} setErrorSub={setErrorModificaSub} setSuccessSub={setSuccessModificaSub}/>
                                </div>
                                )
                            })
                            :
                            <div className="p-1 m-1 testoBlu">
                                Nessuna articolo trovato per il momento...
                            </div>
                            }
                        <Pagination totalPosts={array.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage}/>
                    </div>
                </div>
			</div>
		</div>
	</div>
    );
}

export default AggiungiMuseo;