import React from 'react';
import './Home.css';
import Sfondo_libri from './images/Sfondo_libri.png';
import Foto_Edizioni_Rebis from './images/Foto_Edizioni_Rebis.png';

//PAGINA 404
const NoPage = () => {

    return (
	<div id="Edizioni_Rebis_Home" style={{backgroundImage:'url("'+ Sfondo_libri +'")'}} className='container-fluid p-5'>
		<div className="row align-items-center">
			<div className='col-12'>
				<img id="Foto_Edizioni_Rebis" alt='Foto_Edizioni_Rebis' src={Foto_Edizioni_Rebis} className='m-auto d-block img-fluid'/>
				<div id="Centro_Editoriale_Rebis" className='m-auto d-block'>
					<span>Centro Editoriale Rebis</span>
				</div>
				<div id="Dal_1970_Libri_Rari_di_Scienze" className='m-auto d-block' style={{fontSize:32}}>
					<span>Pagina non trovata...</span>
				</div>
			</div>
		</div>
	</div>
    )
}

export default NoPage;