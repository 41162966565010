import React, { useRef, useState } from "react";
import { notizia } from "./FetchingData";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import Form from "react-bootstrap/esm/Form";

const PannelloChangeNotizia = ({notizia, setErrorSub, setSuccessSub} : {notizia: notizia, setErrorSub : React.Dispatch<React.SetStateAction<boolean>>, setSuccessSub : React.Dispatch<React.SetStateAction<boolean>>}) => {

    const [showModifica, setShowModifica] = useState(false);

    const handleModificaClose = () => setShowModifica(false);
    const handleModificaShow = () => setShowModifica(true);

    const [showCancella, setShowCancella] = useState(false);

    const handleCancellaClose = () => setShowCancella(false);
    const handleCancellaShow = () => setShowCancella(true);

    const [errorImmagineSub, setErrorImmagineSub] = useState(false);
    const [errorNomeNotiziaSub, setNomeNotiziaSub] = useState(false);

    const [errorImageMessage, setErrorImageMessage] = useState("");
    const [errorNomeNotiziaMessage, setErrorNomeNotiziaMessage] = useState("");

    const [immagine, setImmagine] = useState<File | null>(null);
    const [valueImmagine, setValueImmagine] = useState<string | undefined>(undefined);
    const [nomeNotizia, setNomeNotizia] = useState(notizia.titolo);

    const [descrizione, setDescrizione] = useState(notizia.descrizione ?? "");

    const refDragImage = useRef(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.files) {
          setValueImmagine(undefined);
          setImmagine(e.target.files[0]);
        }
      };

    // MODIFICA NOTIZIA CON IMMAGINE

    const modificaNotiziaImmagine = async () => {
        setErrorSub(false);
        setSuccessSub(false);

        setErrorImmagineSub(false);
        setNomeNotiziaSub(false);

        const auth = localStorage.getItem('Authorization') || sessionStorage.getItem('Authorization');

        if (!immagine) {
            setErrorImmagineSub(true);
            setErrorImageMessage("Inserire l'immagine della notizia...");
            return;
        }
        if (immagine && immagine.type !== "image/png" && immagine.type !== "image/jpeg") {
            setErrorImmagineSub(true);
            setErrorImageMessage("File invalido...");
            return;
        }
        setErrorImmagineSub(false);
        if (nomeNotizia === "") {
            setNomeNotiziaSub(true);
            setErrorNomeNotiziaMessage("Inserire il titolo della notizia...");
            return;
        }
        setNomeNotiziaSub(false);

        const formData = new FormData();
        formData.append("file", immagine);
        formData.append("nome", nomeNotizia.trim());
        formData.append("nomeVecchio", notizia.titolo);
        formData.append("descrizione", descrizione);
        formData.append("urlVecchio", notizia.url);
        formData.append("data", notizia.data);

        console.log("Modifica in corso...");

        try {
            // You can write the URL of your server or any other endpoint used for file upload
            const resultModifica = await fetch("https://edizionirebis.com:57447/modifica-notizia-immagine", {
                method: "POST",
                headers: { 'Authorization': `Bearer ${auth}`},
                body: formData
            });

            if (resultModifica.status === 200) {
                setErrorSub(false);
                setSuccessSub(true);
            } else {
                setErrorSub(true);
                setSuccessSub(false);
            }

        } catch (error) {
            console.log(error);
            setErrorSub(true);
            setSuccessSub(false);
            return;
        }

        setValueImmagine(undefined)
        setNomeNotizia(notizia.titolo);
        setDescrizione(notizia.descrizione ?? "");
        handleModificaClose();
    }

    // MODIFICA SENZA IMMAGINE NOTIZIA

    const modificaNotizia = async () => {
        setErrorSub(false);
        setSuccessSub(false);

        setNomeNotiziaSub(false);

        const auth = localStorage.getItem('Authorization') || sessionStorage.getItem('Authorization');

        if (nomeNotizia === "") {
            setNomeNotiziaSub(true);
            setErrorNomeNotiziaMessage("Inserire il nome della notizia...");
            return;
        }
        setNomeNotiziaSub(false);
        
        const send = { nome: nomeNotizia, 
                       nomeVecchio: notizia.titolo, descrizione: descrizione,
                       data: notizia.data }

        console.log("Modifica in corso...");

        try {
            // You can write the URL of your server or any other endpoint used for file upload
            const resultModifica = await fetch("https://edizionirebis.com:57447/modifica-notizia", {
                method: "POST",
                headers: { 'Authorization': `Bearer ${auth}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(send)
            });

            if (resultModifica.status === 200) {
                setErrorSub(false);
                setSuccessSub(true);
            } else {
                setErrorSub(true);
                setSuccessSub(false);
            }

        } catch (error) {
            console.log(error);
            setErrorSub(true);
            setSuccessSub(false);
            return;
        }

        setNomeNotizia(notizia.titolo);
        handleModificaClose();
    }

    // CANCELLA NOTIZIA

    const cancellaNotizia = async () => {
        setErrorSub(false);
        setSuccessSub(false);
        
        const auth = localStorage.getItem('Authorization') || sessionStorage.getItem('Authorization');

        try {
            const postObject = {nome: notizia.titolo.trim(), url: notizia.url, data: notizia.data};

            // You can write the URL of your server or any other endpoint used for file upload
            const resultElimina = await fetch("https://edizionirebis.com:57447/elimina-notizia", {
                method: "POST",
                headers: { 'Authorization': `Bearer ${auth}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(postObject)
            });

            if (resultElimina.status === 200) {
                setErrorSub(false);
                setSuccessSub(true);
            } else {
                setErrorSub(true);
                setSuccessSub(false);
            }

        } catch (error) {
            console.log(error);
            setErrorSub(true);
            return;
        }
        
        handleCancellaClose();
    }

    function onEnterImage() {
        (refDragImage.current as any).className = 'file-input active';
    }

    function onLeaveImage() {
        (refDragImage.current as any).className = 'file-input'
    }

    return (
    <>
    <div className='align-self-center'>
        <img id={"Notizia"+notizia.titolo} src={notizia.url} alt={notizia.titolo} width="165px" height="200px" className='m-auto d-block antiquariatoCopertina libro'/>
        <div className="mt-2" style={{margin:'auto', maxWidth:'75%'}}>
            <div id={notizia.titolo} className="nomeAntiquariato">
                <span>{notizia.titolo}</span>
            </div>
        </div>
        <div className="text-center mt-3">
            <Button onClick={handleModificaShow} variant="outline-secondary" aria-label="Modifica">Modifica</Button>{' '}
            <Button onClick={handleCancellaShow} variant="outline-danger" aria-label="Cancella">Elimina</Button>
        </div>
    </div>
    <Modal show={showModifica} onHide={handleModificaClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Modifica {notizia.titolo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <Form onSubmit={(e) => {
                        e.preventDefault();
                        immagine ? modificaNotiziaImmagine() : modificaNotizia()
                        }
                    }   id="changeNotizia">
                    <br />
                    <div className='text-center'>
                        <p className='testoBlu'>ANTEPRIMA:</p>
                        {
                            immagine ?
                            <img id={"Notizia"+immagine.name} src={URL.createObjectURL(immagine)} alt={immagine.name} width="165px" height="200px" className='antiquariatoCopertina libro'/>
                            :
                            <img id={"Notizia"+notizia.titolo} src={notizia.url} alt={"Notizia"+notizia.titolo} width="165px" height="200px" className='antiquariatoCopertina libro'/>
                        }<br /> <br />
                        <label className="file-input d-inline-grid w-100" htmlFor="file"
                               onDragEnter={onEnterImage} 
                               onDragEnd={onLeaveImage} 
                               onDragLeave={onLeaveImage} 
                               onDragExit={onLeaveImage}
                               onMouseLeave={onLeaveImage}
                               ref={refDragImage}>
                            <div className="drop-zone">
                                <p><b>Inserisci o trascina</b> l'immagine della notizia</p>
                            </div>
                            <input type="file" id="file" 
                            onChange={handleFileChange}
                            accept="image/jpeg"
                            value={valueImmagine}
                            placeholder="Immagine notizia"
                            />
                        </label>
                    </div>
                    <br /><br />
                    <h5 className='text-danger' style={{display: (errorImmagineSub ? 'initial' : 'none'), marginBottom:10}}>
                        {errorImageMessage}
                    </h5>   


                        <Form.Group className="mb-3" controlId={"formBasicModificaNome"+notizia.titolo}>
                            <Form.Label>Nome dell'oggetto</Form.Label>
                            <Form.Control isInvalid={errorNomeNotiziaSub} type="text" placeholder="Titolo della notizia" value={nomeNotizia} 
                            onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setNomeNotizia(e.target.value)} 
                            }
                            />
                            <Form.Control.Feedback type="invalid">
                                {errorNomeNotiziaMessage}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicDescrizione">
                            <Form.Label>Descrizione</Form.Label>
                            <Form.Control type="text" as="textarea" rows={descrizione.length > 50 ? 10 : 2} maxLength={15000} placeholder="Descrizione"
                            value={descrizione} 
                            onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setDescrizione(e.target.value);
                                }
                            } />
                        </Form.Group>

                        <Form.Group className="text-end float-end w-50">
                            <Button variant="outline-success" type="submit">
                                Modifica
                            </Button>
                            {' '}
                            <Button variant="outline-secondary" onClick={handleModificaClose}>
                                Chiudi
                            </Button>
                        </Form.Group>
                    </Form>

        </Modal.Body>
      </Modal>

      <Modal show={showCancella} onHide={handleCancellaClose}>
        <Modal.Header closeButton>
          <Modal.Title className="">Sei sicuro di voler cancellare {notizia.titolo}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="float-end text-end">
                <Button variant="outline-danger" onClick={cancellaNotizia}>
                    Cancella
                </Button>
                {' '}
                <Button variant="outline-secondary" onClick={handleCancellaClose}>
                    Chiudi
                </Button>
            </div>
        </Modal.Body>
      </Modal>
    </>
    )
}

export default PannelloChangeNotizia;